import { call, put, select, putResolve } from "redux-saga/effects";

import { doSavePaymentMethods } from "../actions/paymentMethod";
import { doChangeNewRecharge, doSaveRecharges } from "../actions/recharge";
import { doSaveRecipients } from "../actions/recipient";
import { doSaveRechargeValues } from "../actions/rechargeValue";
import { doSaveBrands } from "../actions/brand";
import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import { fetchPaymentMethods, fetchRechargeBalance } from "../api/customer";
import { fetchRecharges } from "../api/recharge";
import { fetchRecipients } from "../api/recipient";
import { fetchRechargeValues } from "../api/rechargeValue";
import { fetchBrands } from "../api/brand";
import { getBrands } from "../selectors/brand";
import { getLoginRechargeValue, getRechargeValues } from "../selectors/rechargeValue";
import {
  REQUEST_RECHARGE_BALANCE_SUCCESS,
  REQUEST_RECHARGE_BALANCE_FAIL,
} from "../constants/actionTypes";
import { doFetchScheduledRecharges } from "../actions/scheduledRecharge";
import channels from "../enum/channels";
import { getChannel } from "../lib/utils";

function* handleOpenHome() {
  yield put(doShowLoading());

  let error;

  try {
    const rechargeValues = yield select(getRechargeValues);
    if (rechargeValues.constructor === Array && rechargeValues.length === 0) {
      const rechargeValuesFetch = yield call(fetchRechargeValues);

      const channel = getChannel();
      if (channel === channels.cadastro_chip) {
        yield put(
          doSaveRechargeValues({
            ...rechargeValuesFetch.data,
            rechargeValues: rechargeValuesFetch.data.rechargeValues.reverse(),
          })
        );
      } else {
        yield put(doSaveRechargeValues(rechargeValuesFetch.data));
      }

      const loginRechargeValue = yield select(getLoginRechargeValue);
      if (loginRechargeValue && loginRechargeValue.isAvailable)
        yield put(doChangeNewRecharge("rechargeValue", loginRechargeValue));
    }
  } catch (e) {
    error = e;
    console.error("Erro ao carregar os valores de recarga.", e);
  }

  try {
    const paymentMethods = yield call(fetchPaymentMethods);
    yield put(doSavePaymentMethods(paymentMethods.data));
  } catch (e) {
    error = e;
    console.error("Erro ao carregar os métodos de pagamento.", e);
  }

  try {
    const recipients = yield call(fetchRecipients);
    yield put(doSaveRecipients(recipients.data));
  } catch (e) {
    error = e;
    console.error("Erro ao carregar os recipients.", e);
  }

  try {
    const recharges = yield call(fetchRecharges);
    yield putResolve(doFetchScheduledRecharges());
    yield put(doSaveRecharges(recharges.data));
  } catch (e) {
    error = e;
    console.error("Erro ao carregar as recargas.", e);
  }

  if (error) {
    yield put(doShowError("Erro ao carregar a página inicial.", error));
  }

  try {
    const brands = yield select(getBrands);
    if (brands.constructor === Object && Object.keys(brands).length === 0) {
      const brandsFetch = yield call(fetchBrands);
      yield put(doSaveBrands(brandsFetch.data));
    }
  } catch (e) {
    console.error("Erro ao carregar as bandeiras.", e);
  }

  yield put(doHideLoading());
}

function* handleOpenMinhaClaroHome() {
  yield put(doShowLoading());

  let error;

  try {
    const recipients = yield call(fetchRecipients);
    yield put(doSaveRecipients(recipients.data));
  } catch (e) {
    error = e;
    console.error("Erro ao carregar os destinatários.", e);
  }

  try {
    const recharges = yield call(fetchRecharges);
    yield putResolve(doFetchScheduledRecharges());
    yield put(doSaveRecharges(recharges.data));
  } catch (e) {
    error = e;
    console.error("Erro ao carregar as recargas.", e);
  }

  if (error) {
    yield put(doShowError("Erro ao carregar a página inicial.", error));
  }

  try {
    const brands = yield select(getBrands);
    if (brands.constructor === Object && Object.keys(brands).length === 0) {
      const brandsFetch = yield call(fetchBrands);
      yield put(doSaveBrands(brandsFetch.data));
    }
  } catch (e) {
    console.error("Erro ao carregar as bandeiras.", e);
  }

  yield put(doHideLoading());
}

function* handleRequestRechargeBalance() {
  try {
    const response = yield call(fetchRechargeBalance);

    yield put({ type: REQUEST_RECHARGE_BALANCE_SUCCESS, balance: response.data });
  } catch (e) {
    yield put({ type: REQUEST_RECHARGE_BALANCE_FAIL });
  }
}

export { handleOpenHome, handleOpenMinhaClaroHome, handleRequestRechargeBalance };
