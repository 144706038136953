import { FEATURE_FETCH, FEATURE_ADD, PUBLIC_FEATURE_FETCH } from "../constants/actionTypes";

export const doFetchFeatures = () => ({
  type: FEATURE_FETCH,
});

export const doSaveFeatures = (featureName, status) => ({
  type: FEATURE_ADD,
  payload: { featureName, status },
});

export const doFetchPublicFeatures = () => ({
  type: PUBLIC_FEATURE_FETCH,
});
