import styled, { keyframes } from "styled-components";
import loadingIcon from "../../../images/icon_loading.png";

export const LoginBackground = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1002;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
`;

const AppLogoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  animation: ${AppLogoSpin} 1s linear infinite;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url(${loadingIcon}) center center no-repeat;
  background-size: 80px;
  z-index: 1002;
`;
