const cardColors = {
  0: "pink",
  1: "purple",
  2: "green",
  3: "pink",
  4: "purple",
  5: "green",
  6: "pink",
  7: "purple",
  8: "green",
};

export default cardColors;
