import { MENU_SELECT, LOGOUT_REQUEST } from "../../constants/actionTypes";

const INITIAL_STATE = {
  currentPath: "",
};

const applySelectMenu = action => ({
  currentPath: action.path,
});

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_SELECT:
      return applySelectMenu(action);
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default menuReducer;
