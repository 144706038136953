import React from "react";

import { Loading, LoginBackground } from "./LoadingIcon.styles";

const LoadingIcon = () => (
  <LoginBackground>
    <Loading />
  </LoginBackground>
);

export default LoadingIcon;
