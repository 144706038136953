import storage from "local-storage-fallback";
import { call, put, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  doShowModal,
  doShowLoading,
  doHideLoading,
  doShowError,
  doHideModal,
  doShowSnackbar,
} from "../actions/ui";
import {
  doSavePaymentMethods,
  doSuccessRegisterCreditCard,
  doFailureRegisterCreditCard,
  doSuccessRemovePaymentMethod,
  doFetchPaymentMethods,
} from "../actions/paymentMethod";
import { doChangeNewRecharge } from "../actions/recharge";
import { fetchCustomer, fetchPaymentMethods } from "../api/customer";
import {
  requestTokenization,
  requestNewPaymentMethod,
  removePaymentMethod,
  requestPaypalAuthorize,
  requestPaypalCreateAccount,
  requestPaypalDeleteAccount,
} from "../api/paymentMethod";
import modals from "../enum/modals";
import paymentMethodsEnum from "../enum/paymentMethods";
import { getPaymentMethods } from "../selectors/paymentMethod";
import { doSaveCustomer } from "../actions/customer";

const LOCAL_STORAGE_PAYPAL_TOKEN_KEY = "paypalToken";

function* handleFetchPaymentMethods(action) {
  try {
    const paymentMethods = yield select(getPaymentMethods);
    if (paymentMethods.constructor === Array && paymentMethods.length === 0) {
      yield put(doShowLoading());

      const { selectedCardToken } = action.payload;
      const { data } = yield call(fetchPaymentMethods);
      yield put(doSavePaymentMethods(data));

      if (selectedCardToken) {
        const selectedCard =
          data.find(pm => pm.type === paymentMethodsEnum.CREDITO) &&
          data
            .find(pm => pm.type === paymentMethodsEnum.CREDITO)
            .elements.find(cc => cc.token === selectedCardToken);

        yield put(
          doChangeNewRecharge("paymentMethod", {
            type: paymentMethodsEnum.CREDITO,
            data: selectedCard,
          })
        );
      }
    }
  } catch (e) {
    yield put(doShowError("Erro ao carregar os métodos de pagamento.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRemovePaymentMethod(action) {
  yield put(doHideModal());
  yield put(doShowLoading());

  try {
    const { type, token } = action.payload;
    yield call(removePaymentMethod, type, token);

    yield put(doSuccessRemovePaymentMethod(type, token));

    if (action.redirect) {
      yield put(push(action.redirect.path));
    }
  } catch (e) {
    yield put(doShowError("Erro ao deletar meio de pagamento.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRegisterCard(action) {
  yield put(doShowLoading());
  const identifier = storage.getItem("identifier");

  try {
    const { pan, month, year, brandName, color, cardsPath, type, data3ds } = action.payload;
    let { cardToken, lastDigits } = action.payload;
    const { cardCvv } = action.payload;

    if (!lastDigits) {
      lastDigits = pan.substr(pan.length - 4);
    }

    if (!cardToken) {
      const { data } = yield call(requestTokenization, pan, month, year);
      cardToken = data.card.key;
    }

    if (cardsPath) {
      yield call(requestNewPaymentMethod, {
        type,
        data: { token: cardToken },
      });
      yield put(doShowModal(modals.ADD_CARD_SUCCESS));
    } else {
      yield put(
        doChangeNewRecharge("paymentMethod", {
          type,
          data: {
            token: cardToken,
            cvv: cardCvv,
            lastDigits,
            brandName,
            data3ds,
          },
          color,
        })
      );

      yield put(push("pagamento-cvv"));
    }
    const paymentMethods = yield call(fetchPaymentMethods);
    yield put(doSavePaymentMethods(paymentMethods.data));
    const customer = yield call(fetchCustomer, identifier);
    yield put(doSaveCustomer(customer.data));
    yield put(doShowSnackbar("success", "Cartão cadastrado com sucesso"));
  } catch (e) {
    yield put(doShowError("Erro ao cadastrar o cartão.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRegisterCardWithNewLayout(action) {
  yield put(doShowLoading());

  try {
    const { pan, month, year, brandName, color, type, data3ds, bin } = action.payload;
    let { cardToken, lastDigits } = action.payload;
    const { cardCvv } = action.payload;

    if (!lastDigits) {
      lastDigits = pan.substr(pan.length - 4);
    }

    if (!cardToken) {
      const { data } = yield call(requestTokenization, pan, month, year);
      cardToken = data.card.key;
    }

    yield put(
      doChangeNewRecharge("paymentMethod", {
        type,
        data: {
          token: cardToken,
          cvv: cardCvv,
          lastDigits,
          brandName,
          data3ds,
          bin,
        },
        color,
      })
    );
  } catch (e) {
    yield put(doShowError("Erro ao cadastrar o cartão.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestRegisterCreditCard(action) {
  try {
    yield put(doShowLoading());
    const { pan, month, year } = action.payload;
    const { data } = yield call(requestTokenization, pan, month, year);
    const { expirationYear, last, bin, expirationMonth, brand, key } = data.card;
    const creditCard = {
      active: false,
      bin,
      brand,
      expirationMonth: expirationMonth.toString(),
      expirationYear: expirationYear.toString(),
      favorite: false,
      lastDigits: last,
      token: key,
    };
    yield put(doSuccessRegisterCreditCard(creditCard));
  } catch (e) {
    yield put(doFailureRegisterCreditCard());
  } finally {
    yield put(doHideLoading());
  }
}

function* handleCreatePaypalAccount(action) {
  try {
    yield put(doShowLoading());

    const { data } = yield call(requestPaypalAuthorize, action.payload);
    storage.setItem(LOCAL_STORAGE_PAYPAL_TOKEN_KEY, data.token);

    yield put(doHideLoading());

    window.location = data.redirectUrl;
  } catch (e) {
    yield put(push("paypal-failure"));
  }
}

function* handleDeletePaypalAccount(action) {
  try {
    yield call(requestPaypalDeleteAccount, action.billingAgreementId);

    yield put(doFetchPaymentMethods());
  } catch (e) {
    yield put(push("paypal-failure"));
  }
}

function* handlePaypalAuthorized(action) {
  try {
    const token = storage.getItem(LOCAL_STORAGE_PAYPAL_TOKEN_KEY);

    if (!token || token === "") {
      yield put(push("paypal-failure"));
    } else {
      const payload = {
        token,
        ...action.payload,
      };

      yield call(requestPaypalCreateAccount, payload);

      storage.removeItem(LOCAL_STORAGE_PAYPAL_TOKEN_KEY);

      yield put(doFetchPaymentMethods());

      yield put(push("pagamento-paypal"));
    }
  } catch (e) {
    yield put(push("paypal-failure"));
  }
}

export {
  handleFetchPaymentMethods,
  handleRemovePaymentMethod,
  handleRegisterCard,
  handleRegisterCardWithNewLayout,
  handleRequestRegisterCreditCard,
  handleCreatePaypalAccount,
  handleDeletePaypalAccount,
  handlePaypalAuthorized,
};
