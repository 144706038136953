import { call, put } from "redux-saga/effects";

import { doSaveRecipients, doSuccessUpdateRecipients } from "../actions/recipient";
import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import { fetchRecipients, putRecipients, deleteRecipients } from "../api/recipient";

function* handleFetchRecipients() {
  yield put(doShowLoading());

  try {
    const { data } = yield call(fetchRecipients);

    yield put(doSaveRecipients(data));
  } catch (e) {
    yield put(doShowError("Erro ao carregar os destinatários.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleUpdateRecipient(action) {
  yield put(doShowLoading());

  try {
    const { msisdn, payload } = action;

    yield call(putRecipients, msisdn, payload);

    yield put(doSuccessUpdateRecipients(msisdn, payload));
  } catch (e) {
    yield put(doShowError("Erro ao atualizar o destinatário.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleDeleteRecipient(action) {
  yield put(doShowLoading());

  try {
    const { msisdn } = action;

    yield call(deleteRecipients, msisdn);
    const recipients = yield call(fetchRecipients);

    yield put(doSaveRecipients(recipients.data));
  } catch (e) {
    yield put(doShowError("Erro ao excluir o destinatário.", e));
  } finally {
    yield put(doHideLoading());
  }
}

export { handleFetchRecipients, handleUpdateRecipient, handleDeleteRecipient };
