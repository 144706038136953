import {
  INITIALIZE_GA,
  SELECT_RECHARGE_BOX,
  SELECTED_RECHARGE_VALUE,
  SELECTED_PAYMENT_FORM,
  SELECTED_INSTALLMENT,
  CHECK_RECHARGE_TYPE,
  SELECT_RECHARGE_FREQUENCY,
  CREDIT_CARD_SELECTION,
  CREATE_PAYPAL_ACCOUNT_FAILURE,
  SCHEDULED_RECHARGE_BUTTON_CLICK,
  SCHEDULED_RECHARGE_DAY_SELECTION,
  ENCRYPTED_LOGIN_REGISTER,
  LANDING_PAGE_VALUE,
  PAGE_LOAD_PRIVATE_LABEL,
  PAGE_SHOW_PRIVATE_LABEL,
} from "../constants/actionTypes";

export const doInitializeGA = () => ({
  type: INITIALIZE_GA,
});

export const doEncryptedLoginRequest = (channel, status) => ({
  type: ENCRYPTED_LOGIN_REGISTER,
  payload: {
    channel,
    status,
  },
});

export const doSelectedRechargeValue = (value, isScheduled) => ({
  type: SELECTED_RECHARGE_VALUE,
  payload: {
    value,
    isScheduled,
  },
});

export const doSelectRechargeBox = (rechargeOwner, isScheduled) => ({
  type: SELECT_RECHARGE_BOX,
  payload: {
    rechargeOwner,
    isScheduled,
  },
});

export const doSelectedPaymentForm = (paymentForm, isScheduled = false) => ({
  type: SELECTED_PAYMENT_FORM,
  payload: {
    paymentForm,
    isScheduled,
  },
});

export const doSelectedInstallment = (installments, rechargeValue, isScheduled = false) => ({
  type: SELECTED_INSTALLMENT,
  payload: {
    installments,
    rechargeValue,
    isScheduled,
  },
});

export const doCheckRechargeType = isScheduled => ({
  type: CHECK_RECHARGE_TYPE,
  payload: {
    isScheduled,
  },
});

export const doSelectRechargeFrequency = frequency => ({
  type: SELECT_RECHARGE_FREQUENCY,
  payload: {
    frequency,
  },
});

export const doCreditCardSelection = isScheduled => ({
  type: CREDIT_CARD_SELECTION,
  payload: {
    isScheduled,
  },
});

export const doCreatePaypalAccountFailure = () => ({
  type: CREATE_PAYPAL_ACCOUNT_FAILURE,
});

export const doScheduledRechargeButtonClick = () => ({
  type: SCHEDULED_RECHARGE_BUTTON_CLICK,
});

export const doScheduledRechargeDaySelection = day => ({
  type: SCHEDULED_RECHARGE_DAY_SELECTION,
  payload: {
    day,
  },
});

export const doLandingSelected = (value, category) => ({
  type: LANDING_PAGE_VALUE,
  payload: {
    value,
    category,
  },
});

export const doPageLoadPrivateLabel = value => ({
  type: PAGE_LOAD_PRIVATE_LABEL,
  payload: {
    value,
  },
});

export const doPageShowPrivateLabel = value => ({
  type: PAGE_SHOW_PRIVATE_LABEL,
  payload: {
    value,
  },
});
