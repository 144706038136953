const typeUpsell = {
  CONTROLE: "controle",
  FLEX: "flex",
  NORMAL: "normal",
  PREZAO: "prezao",
  CONTROLE_FACIL_1: "controle_facil_1",
  CONTROLE_FACIL_2: "controle_facil_2",
  CONTROLE_CUSTOM_1: "controle_custom_1",
  CONTROLE_CUSTOM_2: "controle_custom_2",
  NORMAL_CUSTOM: "normal_custom",
};

export default typeUpsell;
