export const bonusRecurringPrice = {
  2500: "+1GB de bônus",
  3000: "+2GB de bônus",
  3500: "+3GB de bônus",
  4000: "+4GB de bônus",
  5000: "+5GB de bônus",
  10000: "+10GB de bônus",
  DEFAULT: "+10GB de bônus",
};

// export const bonusRecurringPriceMinhaClaro = {
//   2000: "+1GB de bônus",
//   2500: "+2GB de bônus",
//   3000: "+3GB de bônus",
//   3500: "+4GB de bônus",
//   4000: "+5GB de bônus",
//   5000: "+7GB de bônus",
//   10000: "+10GB de bônus",
//   DEFAULT: "+10GB de bônus",
// };

// Vigente ate o 02/12/2024 em virtude da BF
export const bonusRecurringPriceMinhaClaro = {
  2000: "+11GB de bônus",
  2500: "+12GB de bônus",
  3000: "+13GB de bônus",
  3500: "+14GB de bônus",
  4000: "+15GB de bônus",
  5000: "+17GB de bônus",
  10000: "+20GB de bônus",
  DEFAULT: "+10GB de bônus",
};

export const bonusRecurringPriceDDDs = {
  2000: "+1GB de bônus",
  2500: "+2GB de bônus",
  3000: "+3GB de bônus",
  3500: "+4GB de bônus",
  4000: "+5GB de bônus",
  5000: "+7GB de bônus",
  10000: "+10GB de bônus",
  DEFAULT: "+10GB de bônus",
};
