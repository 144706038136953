import storage from "local-storage-fallback";
import { publicClient, publicTvClient, privateClient } from "./client";

const requestSMS = data => {
  return publicClient.post("/sms-tokens/", JSON.stringify(data));
  // publicClient.post("/sms-tokens/", JSON.stringify(data));
  // return "";
};

const requestLogin = data => publicClient.post("/sessions/", JSON.stringify(data));

const requestCpfLogin = data => publicTvClient.post("/sessions/", JSON.stringify(data));

const requestTemporaryAccessToken = () =>
  privateClient.get(`/sessions/${storage.getItem("identifier")}/tmp/token`);

export { requestSMS, requestLogin, requestCpfLogin, requestTemporaryAccessToken };
