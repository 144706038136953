import {
  RECHARGES_ADD,
  NEW_RECHARGE_CHANGE,
  NEW_RECHARGE_RESET,
  PAYMENT_METHODS_REMOVE_SUCCESS,
  LOGIN_SUCCESS,
  RESET_STATE,
} from "../constants/actionTypes";
import rechargeFrequency from "../enum/rechargeFrequency";
import segments from "../enum/segments";

const INITIAL_STATE = {
  newRecharge: {
    targetMsisdn: "",
    name: "",
    rechargeValue: {},
    paymentMethod: {},
    isScheduled: false,
    editScheduled: false,
    day: "",
    frequency: rechargeFrequency.monthly,
    receipt: false,
    installments: 1,
    partnerExternalId: "",
  },
  recharges: [],
};

const applyGetRecharges = (state, action) => ({
  ...state,
  recharges: action.payload,
});

const applyChangeNewRecharge = (state, action) => {
  const { key, value } = action.payload;
  const { newRecharge } = state;
  const updatedNewRecharge = { ...newRecharge, [key]: value };

  return { ...state, newRecharge: updatedNewRecharge };
};

const applySuccessRemovePaymentMethod = (state, action) => {
  const { token } = action.payload;
  const { newRecharge } = state;

  if (newRecharge.paymentMethod.data && newRecharge.paymentMethod.data.token === token) {
    return {
      ...state,
      newRecharge: {
        ...newRecharge,
        paymentMethod: {},
      },
    };
  }

  return state;
};

const applyResetNewRecharge = state => ({
  ...state,
  newRecharge: INITIAL_STATE.newRecharge,
});

const applySuccessLogin = (state, action) => {
  const isPOS = state.segment === segments.pos;

  const { identifier, recipient, partnerExternalId } = action.payload;
  const { newRecharge } = state;

  const getMsisdn = () => {
    if (isPOS || recipient) return identifier !== recipient ? recipient : identifier;
    return identifier;
  };

  return {
    ...state,
    newRecharge: {
      ...newRecharge,
      targetMsisdn: getMsisdn(),
      partnerExternalId,
    },
  };
};

const rechargeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECHARGES_ADD:
      return applyGetRecharges(state, action);
    case NEW_RECHARGE_CHANGE:
      return applyChangeNewRecharge(state, action);
    case NEW_RECHARGE_RESET:
      return applyResetNewRecharge(state);
    case PAYMENT_METHODS_REMOVE_SUCCESS:
      return applySuccessRemovePaymentMethod(state, action);
    case LOGIN_SUCCESS:
      return applySuccessLogin(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default rechargeReducer;
