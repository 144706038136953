import { call, put, select } from "redux-saga/effects";

import { doSaveRechargeValues } from "../actions/rechargeValue";
import { doChangeNewRecharge } from "../actions/recharge";
import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import { fetchRechargeValues } from "../api/rechargeValue";
import { getRechargeValues, getLoginRechargeValue } from "../selectors/rechargeValue";
import channels from "../enum/channels";
import { getChannel } from "../lib/utils";

function* handleFetchRechargeValues() {
  yield put(doShowLoading());

  try {
    const rechargeValues = yield select(getRechargeValues);

    if (rechargeValues.constructor === Array && rechargeValues.length === 0) {
      const rechargeValuesFetch = yield call(fetchRechargeValues);

      const channel = getChannel();
      if (channel === channels.cadastro_chip) {
        yield put(
          doSaveRechargeValues({
            ...rechargeValuesFetch.data,
            rechargeValues: rechargeValuesFetch.data.rechargeValues.reverse(),
          })
        );
      } else {
        yield put(doSaveRechargeValues(rechargeValuesFetch.data));
      }

      const loginRechargeValue = yield select(getLoginRechargeValue);
      if (loginRechargeValue) {
        yield put(doChangeNewRecharge("rechargeValue", loginRechargeValue));
      }
    }
  } catch (e) {
    yield put(doShowError("Erro ao carregar os valores de recarga.", e));
  } finally {
    yield put(doHideLoading());
  }
}

export { handleFetchRechargeValues };
