import {
  SAVE_GOVISA_CURRENT_STATE,
  SAVE_GOVISA_STATUS,
  UPDATE_GOVISA_SUBSCRIPTION,
  ERROR_ON_GOVISA,
} from "../constants/actionTypes";

const doUpdateGoVisaStatus = (goVisaState, msisdn, reloadId, token, paymentInfo) => {
  return {
    type: SAVE_GOVISA_STATUS,
    payload: {
      current: goVisaState,
      context: {
        msisdn,
        reloadId,
        cardToken: token,
        bin: paymentInfo.bin,
        last: paymentInfo.lastDigits,
      },
    },
  };
};

const doUpdateGoVisaSubscription = cpf => {
  return {
    type: UPDATE_GOVISA_SUBSCRIPTION,
    payload: { cpf },
  };
};

const doUpdateGoVisaCurrentState = current => {
  return {
    type: SAVE_GOVISA_CURRENT_STATE,
    payload: {
      current,
    },
  };
};

const doShowGoVisaError = errorMessage => {
  return {
    type: ERROR_ON_GOVISA,
    payload: {
      error: errorMessage,
    },
  };
};

export {
  doUpdateGoVisaStatus,
  doUpdateGoVisaSubscription,
  doUpdateGoVisaCurrentState,
  doShowGoVisaError,
};
