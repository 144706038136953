import {
  RECHARGE_VALUES_FETCH,
  RECHARGE_VALUES_ADD,
  RESET_RECHARGE_VALUES,
} from "../constants/actionTypes";

const doFetchRechargeValues = () => ({
  type: RECHARGE_VALUES_FETCH,
});

const doSaveRechargeValues = rechargeValues => ({
  type: RECHARGE_VALUES_ADD,
  payload: rechargeValues,
});

const doResetRechargeValues = () => ({
  type: RESET_RECHARGE_VALUES,
});

export { doSaveRechargeValues, doFetchRechargeValues, doResetRechargeValues };
