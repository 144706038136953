import Fingerprint2 from "fingerprintjs2";

const getDeviceFingerprint = () =>
  new Promise(resolve => {
    Fingerprint2.get(components => {
      const result = Fingerprint2.x64hash128(components.map(pair => pair.value).join(), 31);

      resolve(result);
    });
  });

export { getDeviceFingerprint };
