import { createStore } from "redux";
import throttle from "lodash.throttle";

import { loadState, saveState } from "./localStorage";

const configureStore = (rootReducer, middleware) => {
  const persistedState = loadState();
  const store = createStore(rootReducer, persistedState, middleware);

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

  return store;
};

export default configureStore;
