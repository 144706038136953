import { takeLatest, all } from "redux-saga/effects";

import { withCallback } from "redux-saga-callback";
import { handleFetchBrands } from "./brand";
import {
  handleRequestNewRecharge,
  handleFetchRecharges,
  handleRequestNewNoCreditRecharge,
  handleRequestNewPixEncryptedRecharge,
} from "./recharge";
import { handleFetchRecipients, handleUpdateRecipient, handleDeleteRecipient } from "./recipient";
import { handleFetchRechargeValues } from "./rechargeValue";
import { handleFetchTvPackages2 } from "./tvPackage";
import {
  handleRequestSMS,
  handleRequestResendSMS,
  handleRequestLogin,
  handleRequestLoginDeviceToken,
  handleRequestCpfLogin,
  handleRequestEncryptedLogin,
  handleRequestLogout,
} from "./session";
import { handleTvPaymentRequest } from "./tvRecharge";
import {
  handleRegisterCard,
  handleRemovePaymentMethod,
  handleRequestRegisterCreditCard,
  handleFetchPaymentMethods,
  handleCreatePaypalAccount,
  handleDeletePaypalAccount,
  handlePaypalAuthorized,
  handleRegisterCardWithNewLayout,
} from "./paymentMethods";
import { handleLocationChange } from "./location";
import {
  handleFetchScheduledRecharges,
  handleRequestUpdateScheduledRecharge,
  handleRequestDeleteScheduledRecharge,
  handleRequestNewScheduledRecharge,
  handleRequestResetEditableScheduledRecharge,
} from "./scheduledRecharge";
import {
  handleRequestConfirmExpressRecharge,
  handleRequestResetExpressRecharge,
} from "./expressRecharge";

import {
  handleTrackViewPage,
  handleTrackClickSendButton,
  handleTrackTypeConfirmationCode,
  handleTrackAccessPage,
  handleTrackClickScheduledNowButton,
  handleTrackCallbackScheduledRechargesSuccess,
  handleTrackCallbackScheduledRechargesErro,
  handleTrackCallbackRechargeProductDetail,
  handleTrackCallbackRechargeTVProductDetail,
  handleTrackCallbackRechargeCheckout,
  handleTrackCallbackRechargeTVCheckout,
  handleTrackCallbackRechargePurchase,
  handleTrackCallbackScheduledRechargePurchase,
  handleTrackCallbackRechargeTVPurchase,
  handleTrackClickBanner,
  handleTrackCallbackRechargeAddToCard,
  handleTrackCallbackRechargeTVAddToCard,
  handleTrackCallbackProgramRecahargeSuccess,
  handleTrackCallbackProgramRecahargeError,
  handleTrackSelectRechargeValue,
  handleTrackClickFinishRecharge,
} from "./analytics";
import { handleOpenHome, handleOpenMinhaClaroHome, handleRequestRechargeBalance } from "./home";
import { handleFetchPromotions } from "./promotion";
import { handleFetchFeature, handleFetchPublicFeatures } from "./feature";
import { handleCallbackRecharge, handleUpdateGoVisaSubscription } from "./goVisa";

import {
  BRANDS_FETCH,
  CARD_REGISTER,
  NEW_RECHARGE_REQUEST,
  SMS_REQUEST,
  RESEND_SMS_REQUEST,
  LOGIN_REQUEST,
  CPF_LOGIN_REQUEST,
  ENCRYPTED_LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RECHARGES_FETCH,
  RECIPIENTS_FETCH,
  RECHARGE_VALUES_FETCH,
  TV_PACKAGES_FETCH,
  TV_PAYMENT_REQUEST,
  LOCATION_CHANGE,
  PAYMENT_METHODS_FETCH,
  PAYMENT_METHODS_REMOVE_REQUEST,
  SCHEDULED_RECHARGES_FETCH,
  NEW_SCHEDULED_RECHARGE_REQUEST,
  RECHARGES_SUCCESS,
  SCHEDULED_RECHARGES_SUCCESS,
  SCHEDULED_RECHARGES_ERROR,
  UPDATE_SCHEDULED_RECHARGE_REQUEST,
  DELETE_SCHEDULED_RECHARGE_REQUEST,
  HOME_OPEN,
  MINHA_CLARO_HOME_OPEN,
  REGISTER_CREDIT_CARD_REQUEST,
  NEW_TV_RECHARGE_SUCCESS,
  CONFIRM_EXPRESS_RECHARGE_REQUEST,
  RESET_EXPRESS_RECHARGE_REQUEST,
  RESET_EDITABLE_SCHEDULED_RECHARGE_REQUEST,
  CREATE_PAYPAL_ACCOUNT,
  DELETE_PAYPAL_ACCOUNT,
  PROCESS_PAYPAL_AUTHORIZED,
  CLICK_BANNER,
  REQUEST_RECHARGE_BALANCE,
  REQUEST_UPDATE_RECIPIENT,
  REQUEST_DELETE_RECIPIENT,
  NEW_NO_CREDIT_RECHARGE_REQUEST,
  PROMOTION_FETCH,
  FEATURE_FETCH,
  PUBLIC_FEATURE_FETCH,
  CARD_REGISTER_NEW_LAYOUT,
  UPDATE_GOVISA_SUBSCRIPTION,
  NEW_PIX_ENCRYPTED_RECHARGE_REQUEST,
  OTP_VALIDATE_SMS_TOKEN,
  OTP_RESEND_SMS_TOKEN,
  DELETE_CUSTOMER,
} from "../constants/actionTypes";
import { handleResendSmsToken, handleRevalidateSmsToken } from "./revalidate";
import { handleDeleteCustomer } from "./customer";

export default function* rootSaga() {
  yield all([
    takeLatest(BRANDS_FETCH, handleFetchBrands),
    takeLatest(CARD_REGISTER, handleRegisterCard),
    takeLatest(CARD_REGISTER_NEW_LAYOUT, handleRegisterCardWithNewLayout),
    takeLatest(NEW_RECHARGE_REQUEST, handleRequestNewRecharge),
    takeLatest(NEW_PIX_ENCRYPTED_RECHARGE_REQUEST, handleRequestNewPixEncryptedRecharge),
    takeLatest(NEW_SCHEDULED_RECHARGE_REQUEST, handleRequestNewScheduledRecharge),
    takeLatest(SMS_REQUEST, handleRequestSMS),
    takeLatest(RESEND_SMS_REQUEST, handleRequestResendSMS),
    takeLatest(LOGIN_REQUEST, handleRequestLogin),
    takeLatest(LOGIN_REQUEST, handleRequestLoginDeviceToken),
    takeLatest(CPF_LOGIN_REQUEST, handleRequestCpfLogin),
    takeLatest(ENCRYPTED_LOGIN_REQUEST, handleRequestEncryptedLogin),
    takeLatest(LOGOUT_REQUEST, handleRequestLogout),
    takeLatest(RECHARGES_FETCH, handleFetchRecharges),
    takeLatest(RECIPIENTS_FETCH, handleFetchRecipients),
    takeLatest(RECHARGE_VALUES_FETCH, withCallback(handleFetchRechargeValues)),
    takeLatest(TV_PACKAGES_FETCH, handleFetchTvPackages2),
    takeLatest(TV_PAYMENT_REQUEST, handleTvPaymentRequest),
    takeLatest(LOCATION_CHANGE, handleLocationChange),
    takeLatest(PAYMENT_METHODS_FETCH, handleFetchPaymentMethods),
    takeLatest(SCHEDULED_RECHARGES_FETCH, handleFetchScheduledRecharges),
    takeLatest(UPDATE_SCHEDULED_RECHARGE_REQUEST, handleRequestUpdateScheduledRecharge),
    takeLatest(DELETE_SCHEDULED_RECHARGE_REQUEST, handleRequestDeleteScheduledRecharge),
    takeLatest(HOME_OPEN, handleOpenHome),
    takeLatest(MINHA_CLARO_HOME_OPEN, handleOpenMinhaClaroHome),
    takeLatest(REGISTER_CREDIT_CARD_REQUEST, handleRequestRegisterCreditCard),
    takeLatest(PAYMENT_METHODS_REMOVE_REQUEST, handleRemovePaymentMethod),
    takeLatest(CONFIRM_EXPRESS_RECHARGE_REQUEST, handleRequestConfirmExpressRecharge),
    takeLatest(RESET_EXPRESS_RECHARGE_REQUEST, handleRequestResetExpressRecharge),
    takeLatest(
      RESET_EDITABLE_SCHEDULED_RECHARGE_REQUEST,
      handleRequestResetEditableScheduledRecharge
    ),

    takeLatest(LOCATION_CHANGE, handleTrackViewPage),
    takeLatest(SMS_REQUEST, handleTrackClickSendButton),
    takeLatest(LOGIN_REQUEST, handleTrackTypeConfirmationCode),
    takeLatest(LOCATION_CHANGE, handleTrackAccessPage),
    takeLatest(NEW_RECHARGE_REQUEST, handleTrackClickScheduledNowButton),
    takeLatest(SCHEDULED_RECHARGES_SUCCESS, handleTrackCallbackScheduledRechargesSuccess),
    takeLatest(SCHEDULED_RECHARGES_ERROR, handleTrackCallbackScheduledRechargesErro),
    takeLatest(LOCATION_CHANGE, handleTrackCallbackRechargeProductDetail),
    takeLatest(LOCATION_CHANGE, handleTrackCallbackRechargeTVProductDetail),
    takeLatest(LOCATION_CHANGE, handleTrackCallbackRechargeAddToCard),
    takeLatest(LOCATION_CHANGE, handleTrackCallbackRechargeTVAddToCard),
    takeLatest(LOCATION_CHANGE, handleTrackCallbackRechargeCheckout),
    takeLatest(LOCATION_CHANGE, handleTrackCallbackRechargeTVCheckout),
    takeLatest(RECHARGES_SUCCESS, handleTrackCallbackRechargePurchase),
    takeLatest(RECHARGES_SUCCESS, handleCallbackRecharge),
    takeLatest(SCHEDULED_RECHARGES_SUCCESS, handleTrackCallbackScheduledRechargePurchase),
    takeLatest(NEW_TV_RECHARGE_SUCCESS, handleTrackCallbackRechargeTVPurchase),
    takeLatest(CREATE_PAYPAL_ACCOUNT, handleCreatePaypalAccount),
    takeLatest(DELETE_PAYPAL_ACCOUNT, handleDeletePaypalAccount),
    takeLatest(PROCESS_PAYPAL_AUTHORIZED, handlePaypalAuthorized),
    takeLatest(CLICK_BANNER, handleTrackClickBanner),
    takeLatest(SCHEDULED_RECHARGES_SUCCESS, handleTrackCallbackProgramRecahargeSuccess),
    takeLatest(SCHEDULED_RECHARGES_ERROR, handleTrackCallbackProgramRecahargeError),
    takeLatest(LOCATION_CHANGE, handleTrackSelectRechargeValue),
    takeLatest(LOCATION_CHANGE, handleTrackClickFinishRecharge),
    takeLatest(REQUEST_RECHARGE_BALANCE, handleRequestRechargeBalance),
    takeLatest(REQUEST_UPDATE_RECIPIENT, handleUpdateRecipient),
    takeLatest(REQUEST_DELETE_RECIPIENT, handleDeleteRecipient),
    takeLatest(DELETE_CUSTOMER, handleDeleteCustomer),
    takeLatest(NEW_NO_CREDIT_RECHARGE_REQUEST, handleRequestNewNoCreditRecharge),
    takeLatest(PROMOTION_FETCH, handleFetchPromotions),
    takeLatest(FEATURE_FETCH, handleFetchFeature),
    takeLatest(PUBLIC_FEATURE_FETCH, handleFetchPublicFeatures),
    takeLatest(UPDATE_GOVISA_SUBSCRIPTION, handleUpdateGoVisaSubscription),
    takeLatest(OTP_VALIDATE_SMS_TOKEN, handleRevalidateSmsToken),
    takeLatest(OTP_RESEND_SMS_TOKEN, handleResendSmsToken),
  ]);
}
