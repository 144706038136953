import {
  ERROR_ON_GOVISA,
  RESET_STATE,
  SAVE_GOVISA_CURRENT_STATE,
  SAVE_GOVISA_STATUS,
} from "../constants/actionTypes";
import { INITIAL_STATE } from "../reduxStates/GoVisaState";

const goVisaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_GOVISA_STATUS: {
      const { current, context } = action.payload;
      return { ...state, loading: false, error: "", current, context };
    }
    case SAVE_GOVISA_CURRENT_STATE: {
      const { current } = action.payload;
      return { ...state, loading: false, error: "", current };
    }
    case ERROR_ON_GOVISA: {
      const { error } = action.payload;
      return { ...state, loading: false, error };
    }
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default goVisaReducer;
