import storage from "local-storage-fallback";
import { privateClient } from "./client";

const fetchRecipients = () =>
  privateClient.get(`/customers/${storage.getItem("identifier")}/recipients`);

const putRecipients = (msisdn, payload) => {
  privateClient.put(`/customers/${storage.getItem("identifier")}/recipients/${msisdn}`, payload);
};

const deleteRecipients = msisdn =>
  privateClient.delete(`/customers/${storage.getItem("identifier")}/recipients/${msisdn}`);

export { fetchRecipients, putRecipients, deleteRecipients };
