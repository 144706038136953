import {
  SHOW_OTP_MODAL,
  HIDE_OTP_MODAL,
  VALIDATE_TOKEN,
  UNVALIDATE_TOKEN,
  OTP_VALIDATE_SMS_TOKEN,
  RESET_STATE_OTP,
  OTP_RESEND_SMS_TOKEN,
} from "../constants/actionTypes";

const doShowOTPModal = () => ({
  type: SHOW_OTP_MODAL,
});

const doHideOTPModal = () => ({
  type: HIDE_OTP_MODAL,
});

const doValidateSMSToken = () => ({
  type: VALIDATE_TOKEN,
});

const doUnvalidateSMSToken = () => ({
  type: UNVALIDATE_TOKEN,
});

const doRevalidateToken = (smsToken, paymentMethodConfirmed, identifier, targetMsisdn) => ({
  type: OTP_VALIDATE_SMS_TOKEN,
  payload: { smsToken, paymentMethodConfirmed, identifier, targetMsisdn },
});

const doResendSmsToken = msisdn => ({
  type: OTP_RESEND_SMS_TOKEN,
  payload: { msisdn },
});

const doResetState = () => ({
  type: RESET_STATE_OTP,
});

export {
  doShowOTPModal,
  doHideOTPModal,
  doRevalidateToken,
  doUnvalidateSMSToken,
  doValidateSMSToken,
  doResetState,
  doResendSmsToken,
};
