import { knownTvErrors } from "../enum/knownErrors";

function translateError(errorList) {
  try {
    if (errorList !== null && errorList !== undefined && errorList.length > 0) {
      const firstError = errorList[0];

      if (firstError.code && knownTvErrors[firstError.code]) {
        return knownTvErrors[firstError.code];
      }
    }
  } catch (e) {
    console.log("ERROR ON PARSER OF THUNDERCATS MESSAGE: ", errorList, e);
  }

  return knownTvErrors.LI0000;
}

export default translateError;
