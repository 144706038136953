import storage from "local-storage-fallback";
import { privateClient } from "./client";

const fetchScheduledRecharges = () =>
  privateClient.get(`/customers/${storage.getItem("identifier")}/scheduled-recharges`);

const requestUpdateScheduledRecharge = data =>
  privateClient.put(
    `/customers/${storage.getItem("identifier")}/scheduled-recharges`,
    JSON.stringify(data)
  );

const requestDeleteScheduledRecharge = externalId =>
  privateClient.delete(
    `/customers/${storage.getItem("identifier")}/scheduled-recharges/${externalId}`
  );

const requestNewScheduledRecharge = data =>
  privateClient.post(
    `/customers/${storage.getItem("identifier")}/scheduled-recharges`,
    JSON.stringify(data)
  );

export {
  fetchScheduledRecharges,
  requestUpdateScheduledRecharge,
  requestDeleteScheduledRecharge,
  requestNewScheduledRecharge,
};
