import { SNACKBAR_SHOW, SNACKBAR_HIDE, LOGOUT_REQUEST } from "../../constants/actionTypes";

const INITIAL_STATE = {
  isVisible: false,
  text: "",
  type: "error",
};

const applyShowSnackbar = action => ({
  ...action.payload,
  type: action.payload.type,
  isVisible: true,
});

const applyHideSnackbar = () => ({
  ...INITIAL_STATE,
});

const snackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SNACKBAR_SHOW:
      return applyShowSnackbar(action);
    case SNACKBAR_HIDE:
      return applyHideSnackbar();
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default snackbarReducer;
