import { call, put } from "redux-saga/effects";

import { fetchPromotions } from "../api/promotion";
import { doSavePromotions } from "../actions/promotion";

function* handleFetchPromotions() {
  try {
    const promotionsFetch = yield call(fetchPromotions);
    yield put(doSavePromotions(promotionsFetch.data));
  } catch (e) {
    console.error("Erro ao carregar as promoções.", e);
  }
}

export { handleFetchPromotions };
