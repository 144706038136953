const getLoginRechargeValue = ({ rechargeValueState }) => {
  const { rechargeValues, loginRechargeValue } = rechargeValueState;

  return rechargeValues.find(rechargeValue => rechargeValue.value === loginRechargeValue);
};

const getRechargeValues = ({ rechargeValueState }) => {
  const loginRechargeValue = getLoginRechargeValue({ rechargeValueState });

  if (loginRechargeValue && loginRechargeValue.value) {
    if (rechargeValueState.rechargeValues.find(rv => rv.id === loginRechargeValue.id).isAvailable) {
      return (
        rechargeValueState.rechargeValues.filter(
          rechargeValue => rechargeValue.value >= loginRechargeValue.value
        ) || []
      );
    }
  }

  return rechargeValueState.rechargeValues || [];
};

const getRechargeValuesAverageTicket = ({ rechargeValueState }) => {
  const { rechargeValues, averageTicket } = rechargeValueState;
  if (typeof averageTicket === "number") {
    const avgTicketPosition = rechargeValues.findIndex(valueObj => {
      return valueObj.value >= averageTicket;
    });
    return rechargeValues.slice(avgTicketPosition, rechargeValues.length);
  }
  return rechargeValues;
};

export { getRechargeValues, getRechargeValuesAverageTicket, getLoginRechargeValue };
