import storage from "local-storage-fallback";

import {
  CARD_REGISTER,
  PAYMENT_METHODS_FETCH,
  PAYMENT_METHODS_ADD,
  PAYMENT_METHODS_STATUS_RESET,
  REGISTER_CREDIT_CARD_REQUEST,
  REGISTER_CREDIT_CARD_SUCCESS,
  REGISTER_CREDIT_CARD_FAILURE,
  PAYMENT_METHODS_REMOVE_REQUEST,
  PAYMENT_METHODS_REMOVE_SUCCESS,
  CREATE_PAYPAL_ACCOUNT,
  DELETE_PAYPAL_ACCOUNT,
  PROCESS_PAYPAL_AUTHORIZED,
  CARD_REGISTER_NEW_LAYOUT,
} from "../constants/actionTypes";
import paymentMethodsEnum from "../enum/paymentMethods";

import { PAYPAL_FAILURE_PATH, PAYPAL_SUCCESS_PATH } from "../constants/paths";

const doFetchPaymentMethods = (selectedCardToken = null) => ({
  type: PAYMENT_METHODS_FETCH,
  payload: { selectedCardToken },
});

const doSavePaymentMethods = paymentMethods => ({
  type: PAYMENT_METHODS_ADD,
  payload: paymentMethods,
});

const doResetPaymentMethodsStatus = () => ({
  type: PAYMENT_METHODS_STATUS_RESET,
});

const doRequestRegisterCreditCard = (pan, month, year) => ({
  type: REGISTER_CREDIT_CARD_REQUEST,
  payload: {
    pan,
    month,
    year,
  },
});

const doSuccessRegisterCreditCard = creditCard => ({
  type: REGISTER_CREDIT_CARD_SUCCESS,
  payload: {
    creditCard,
  },
});

const doFailureRegisterCreditCard = () => ({
  type: REGISTER_CREDIT_CARD_FAILURE,
});

const doRequestRemovePaymentMethod = (type, token, redirect) => ({
  type: PAYMENT_METHODS_REMOVE_REQUEST,
  payload: { type, token },
  redirect,
});

const doSuccessRemovePaymentMethod = (type, token) => ({
  type: PAYMENT_METHODS_REMOVE_SUCCESS,
  payload: { type, token },
});

const doRegisterCard = (
  pan,
  month,
  year,
  brandName,
  color,
  cardsPath,
  type = paymentMethodsEnum.CREDITO,
  cardToken,
  cardCvv,
  lastDigits,
  data3ds
) => ({
  type: CARD_REGISTER,
  payload: {
    pan,
    month,
    year,
    brandName,
    color,
    cardsPath,
    type,
    cardToken,
    cardCvv,
    lastDigits,
    data3ds,
  },
});

const doRegisterCardWithNewLayout = (
  pan,
  month,
  year,
  brandName,
  color,
  type = paymentMethodsEnum.CREDITO,
  cardToken,
  cardCvv,
  lastDigits,
  data3ds,
  bin
) => ({
  type: CARD_REGISTER_NEW_LAYOUT,
  payload: {
    pan,
    month,
    year,
    brandName,
    color,
    type,
    cardToken,
    cardCvv,
    lastDigits,
    data3ds,
    bin,
  },
});

const doCreatePaypalAccount = () => ({
  type: CREATE_PAYPAL_ACCOUNT,
  payload: {
    cancelUrl: `${window.location.protocol}//${window.location.host}${PAYPAL_FAILURE_PATH.replace(
      ":channel",
      storage.getItem("channelPath")
    )}`,
    returnUrl: `${window.location.protocol}//${window.location.host}${PAYPAL_SUCCESS_PATH.replace(
      ":channel",
      storage.getItem("channelPath")
    )}`,
  },
});

const doDeletePaypalAccount = billingAgreementId => ({
  type: DELETE_PAYPAL_ACCOUNT,
  billingAgreementId,
});

const doProcessPaypalAuthorized = customerId => ({
  type: PROCESS_PAYPAL_AUTHORIZED,
  payload: {
    customer_id: customerId,
  },
});

export {
  doFetchPaymentMethods,
  doSavePaymentMethods,
  doResetPaymentMethodsStatus,
  doRequestRegisterCreditCard,
  doSuccessRegisterCreditCard,
  doFailureRegisterCreditCard,
  doRequestRemovePaymentMethod,
  doSuccessRemovePaymentMethod,
  doRegisterCard,
  doRegisterCardWithNewLayout,
  doCreatePaypalAccount,
  doDeletePaypalAccount,
  doProcessPaypalAuthorized,
};
