import { call, put, select } from "redux-saga/effects";

import { doSaveBrands } from "../actions/brand";
import { getBrands } from "../selectors/brand";
import { doShowLoading, doHideLoading } from "../actions/ui";
import { fetchBrands } from "../api/brand";

function* handleFetchBrands() {
  yield put(doShowLoading());

  try {
    const brands = yield select(getBrands);
    if (brands.constructor === Object && Object.keys(brands).length === 0) {
      const { data } = yield call(fetchBrands);
      yield put(doSaveBrands(data));
    }
  } catch (e) {
    console.error("Erro ao carregar as bandeiras.", e);
  } finally {
    yield put(doHideLoading());
  }
}

export { handleFetchBrands };
