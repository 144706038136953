const getNewTvRecharge = ({ tvRechargeState }) => tvRechargeState.newTvRecharge;

const getRechargeResult = ({ tvRechargeState }) => tvRechargeState.lastRechargeResult;

const getProduct = ({ tvRechargeState }) => tvRechargeState.newTvRecharge.product;

function getProductPlan({ tvRechargeState }) {
  return tvRechargeState.newTvRecharge.product.plan;
}

export { getNewTvRecharge, getProduct, getProductPlan, getRechargeResult };
