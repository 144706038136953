import { CHANGE_MEDIA, LOGOUT_REQUEST } from "../../constants/actionTypes";

const INITIAL_STATE = {
  mobile: false,
  tablet: false,
};

const mediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_MEDIA:
      return action.media;
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default mediaReducer;
