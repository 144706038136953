import {
  EDITABLE_SCHEDULED_RECHARGE_SAVE,
  EDITABLE_SCHEDULED_RECHARGE_CHANGE,
  EDITABLE_SCHEDULED_RECHARGE_RESET,
  SCHEDULED_RECHARGES_SAVE,
  SCHEDULED_RECHARGES_FILTER,
  UPDATE_SCHEDULED_RECHARGE_SUCCESS,
  UPDATE_SCHEDULED_RECHARGE_FAILURE,
  EDITABLE_SCHEDULED_RECHARGE_STATUS_CHANGE,
  RESET_STATE,
  MANAGE_SCHEDULE_RECHARGE,
  HISTORIC_SCHEDULED_RECHARGES_SAVE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  editableScheduledRecharge: {},
  scheduledRecharges: [],
  recharges: [],
  status: "",
  manageScheduleRecharge: {},
};

const applySaveEditableScheduledRecharge = (state, action) => ({
  ...state,
  editableScheduledRecharge: action.payload,
});

const applyChangeEditableScheduledRecharge = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    editableScheduledRecharge: { ...state.editableScheduledRecharge, [key]: value },
  };
};

const applyResetEditableScheduledRecharge = state => ({
  ...state,
  editableScheduledRecharge: {},
  status: "",
});

const applySaveScheduledRecharges = (state, action) => ({
  ...state,
  scheduledRecharges: action.payload,
});

const applyHistoricSaveScheduledRecharges = (state, action) => ({
  ...state,
  recharges: action.payload,
});

const applyFilterScheduledRecharges = (state, action) => {
  const { externalId } = action.payload;
  const filteredScheduledRecharges = state.scheduledRecharges.filter(
    scheduledRecharge => scheduledRecharge.id !== externalId
  );

  return { ...state, scheduledRecharges: filteredScheduledRecharges };
};

const applySuccessUpdateScheduledRecharge = state => ({
  ...state,
  status: "success",
});

const applyFailureUpdateScheduledRecharge = state => ({
  ...state,
  status: "failure",
});

const applyChangeEditableScheduledRechargeStatus = (state, action) => ({
  ...state,
  status: action.payload.status,
});

const applyManagecheduledRecharge = (state, action) => ({
  ...state,
  manageScheduleRecharge: action.payload,
});

const scheduledRechargeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDITABLE_SCHEDULED_RECHARGE_SAVE:
      return applySaveEditableScheduledRecharge(state, action);
    case EDITABLE_SCHEDULED_RECHARGE_CHANGE:
      return applyChangeEditableScheduledRecharge(state, action);
    case EDITABLE_SCHEDULED_RECHARGE_RESET:
      return applyResetEditableScheduledRecharge(state);
    case SCHEDULED_RECHARGES_SAVE:
      return applySaveScheduledRecharges(state, action);
    case HISTORIC_SCHEDULED_RECHARGES_SAVE:
      return applyHistoricSaveScheduledRecharges(state, action);
    case SCHEDULED_RECHARGES_FILTER:
      return applyFilterScheduledRecharges(state, action);
    case UPDATE_SCHEDULED_RECHARGE_SUCCESS:
      return applySuccessUpdateScheduledRecharge(state, action);
    case UPDATE_SCHEDULED_RECHARGE_FAILURE:
      return applyFailureUpdateScheduledRecharge(state, action);
    case EDITABLE_SCHEDULED_RECHARGE_STATUS_CHANGE:
      return applyChangeEditableScheduledRechargeStatus(state, action);
    case MANAGE_SCHEDULE_RECHARGE:
      return applyManagecheduledRecharge(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default scheduledRechargeReducer;
