import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { isSessionLoggedIn } from "../../../../selectors/session";
import { getMedia } from "../../../../selectors/ui";
import { getCurrentChannel } from "../../../../lib/channelValidator";

const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  exact,
  mobile: MobileComponent,
  media,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated) {
        return (
          <>
            {media.mobile || media.tablet ? (
              <MobileComponent {...props} />
            ) : (
              <Component {...props} />
            )}
          </>
        );
      }

      const [channelPath] = getCurrentChannel(props.location);
      const pathname = `/${channelPath}/login`;

      return <Redirect push to={{ pathname, state: { from: props.location } }} />;
    }}
  />
);

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  media: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exact: PropTypes.bool.isRequired,
  mobile: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: isSessionLoggedIn(state),
  media: getMedia(state),
});

export default connect(mapStateToProps)(PrivateRoute);
