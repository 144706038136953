import storage from "local-storage-fallback";
import { call, select } from "redux-saga/effects";
import { getGaUserId } from "../selectors/session";
import { getRechargeValue, isScheduled } from "../selectors/recharge";
import { getRechargeValues } from "../selectors/rechargeValue";
import { getProduct, getProductPlan } from "../selectors/tvRecharge";
import { getTvPackages } from "../selectors/tvPackage";
import envs from "../enum/envs";

function formatProductValue(value) {
  const formattedValue = value / 100;

  // eslint-disable-next-line no-restricted-globals
  return !isNaN(formattedValue) ? formattedValue.toFixed(2) : "";
}

function createProductName(value) {
  const formattedValue = formatProductValue(value).replace(".00", "");
  return `recarga-${formattedValue}`;
}

function createTracker() {
  if (
    process.env.REACT_APP_NODE_ENV !== envs.production &&
    process.env.REACT_APP_GTM_LOG === "true"
  ) {
    return console.log.bind(console);
  }

  if (!window.dataLayer || !window.dataLayer.push) {
    console.log("window.dataLayer.push is not available");
    return console.log.bind(console);
  }

  return function dataLayerTracker(event) {
    try {
      window.dataLayer.push(event);
    } catch (e) {
      console.log("dataLayerTracker failed", e);
    }
  };
}

const tracker = createTracker();

function handleTrack(type, category, action, label) {
  return call(tracker, {
    event: type,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
}

function getAccessType() {
  const channel = storage.getItem("channelPath");
  switch (channel) {
    case "recarga":
      return "selecao_numero";
    case "tv":
      return "selecao_data_recarca";
    default:
      return "";
  }
}

function* handleTrackViewPage() {
  const UserId = yield select(getGaUserId);
  const accessType = getAccessType();
  yield call(tracker, {
    dimension1: UserId,
    dimension2: accessType,
    dimension3: "nao_disponivel",
  });
}

// 1 - Clique em prosseguir
function* handleTrackClickSendButton() {
  yield handleTrack("event", "claro:recarga-pre", "clique:prosseguir-recarga", "digitou-numero");
}

// 2 - Código de Confirmação
function* handleTrackTypeConfirmationCode() {
  yield handleTrack("event", "claro:recarga-pre", "clique:enviar", "sms:codigo-confirmacao");
}

// 3 - Selecionou Valor Recarga
function* handleTrackSelectRechargeValue(action) {
  const { pathname } = action.payload;
  if (pathname === "/recarga/credito") {
    const rechargeValue = yield select(getRechargeValue);
    yield handleTrack(
      "event",
      "claro:recarga-pre",
      "clique:valor-recarga",
      `recarga:${formatProductValue(rechargeValue.value)}`
    );
  }
}

// 4 - Concluir Recarga
function* handleTrackClickFinishRecharge(action) {
  const { pathname } = action.payload;
  if (pathname === " /recarga/confirmacao") {
    yield handleTrack("event", "claro:recarga-pre", "clique:concluir-recarga", "recarga-pre");
  }
}

function* handleTrackAccessPage(action) {
  const { pathname } = action.payload;

  // 5 - Clique - Minhas recargas
  if (pathname === "/recarga/minhas-recargas") {
    yield handleTrack("event", "claro:recarga-pre", "clique:minhas-recargas", "menu-principal");
  }

  // 6 - Clique - Meus cartoes
  if (pathname === "/recarga/credito") {
    yield handleTrack("event", "claro:recarga-pre", "clique:meus-cartoes", "menu-principal");
  }
}

// 7 - Clique - Programar Recarga
function* handleTrackClickScheduledNowButton() {
  yield handleTrack("event", "claro:recarga-pre", "clique:programar-agora", "recarga-claro");
}

// 8 - Clique - Banner
function* handleTrackClickBanner(action) {
  const { label } = action.payload;

  yield handleTrack("event", "claro:recarga-pre", "clique:banner", label);
}

// 9 - Callback de Sucesso de Recarga
function* handleTrackCallbackProgramRecahargeSuccess() {
  yield handleTrack("conversion", "claro:recarga-pre", "recarga-pre:tentativa", "sucesso");
}

// 9 - Callback de Sucesso de Recarga
function* handleTrackCallbackProgramRecahargeError() {
  yield handleTrack(
    "conversion",
    "claro:recarga-pre",
    "recarga-pre:tentativa",
    "erro:motivo-desconhecido"
  );
}

// 10 - Calback - Programacao de recarga
function* handleTrackCallbackScheduledRechargesSuccess() {
  yield handleTrack("conversion", "claro:recarga-pre", "recarga-progamada:tentativa", "sucesso");
}

function* handleTrackCallbackScheduledRechargesErro() {
  yield handleTrack(
    "conversion",
    "claro:recarga-pre",
    "recarga-progamada:tentativa",
    "erro:motivo-desconhecido"
  );
}

// Enhanced Ecommerce: 1 - Product Detail
function* handleTrackCallbackRechargeProductDetail(action) {
  const { pathname } = action.payload;
  if (pathname === "/recarga/home") {
    const rechargeValues = yield select(getRechargeValues);
    yield call(tracker, {
      event: "productDetail",
      eventCategory: "claro:recarga-pre",
      eventAction: "enhanced-ecommerce",
      eventLabel: "product-detail",
      ecommerce: {
        click: {
          actionField: { list: "recarregue-agora" },
          products: rechargeValues.map(rechargeValue => ({
            name: createProductName(rechargeValue.value),
            id: rechargeValue.id,
            price: formatProductValue(rechargeValue.value),
            brand: "claro",
            category: "claro-movel",
            list: "recarregue-agora",
          })),
        },
      },
    });
  }
}

function* handleTrackCallbackRechargeTVProductDetail(action) {
  const { pathname } = action.payload;
  if (pathname === "/tv/home") {
    const tvPackages = yield select(getTvPackages);

    yield call(tracker, {
      event: "productDetail",
      eventCategory: "claro:recarga-pre",
      eventAction: "enhanced-ecommerce",
      eventLabel: "product-detail",
      ecommerce: {
        click: {
          actionField: { list: "recarregue-agora" },
          products: JSON.stringify(tvPackages),
        },
      },
    });
  }
}

// Enhanced Ecommerce: 2 - AddToCart
function* handleTrackCallbackRechargeAddToCard(action) {
  const { pathname } = action.payload;
  if (pathname === "/recarga/numero") {
    const recharge = yield select(getRechargeValue);
    yield call(tracker, {
      event: "addToCart",
      eventCategory: "claro:recarga-pre",
      eventAction: "enhanced-ecommerce",
      eventLabel: "add-to-cart",
      ecommerce: {
        add: {
          products: [
            {
              name: createProductName(recharge.value),
              id: recharge.id,
              price: formatProductValue(recharge.value),
              brand: "claro",
              category: "claro-movel",
              variant: "nao_disponivel",
              quantity: "1",
            },
          ],
        },
      },
    });
  }
}

function* handleTrackCallbackRechargeTVAddToCard(action) {
  const { pathname } = action.payload;
  if (pathname === "/tv/pagamento") {
    const plan = yield select(getProductPlan);
    yield call(tracker, {
      event: "addToCart",
      eventCategory: "claro:recarga-pre",
      eventAction: "enhanced-ecommerce",
      eventLabel: "add-to-cart",
      ecommerce: {
        add: {
          products: [
            {
              name: createProductName(plan.price),
              id: plan.id,
              price: formatProductValue(plan.price),
              brand: "claro",
              category: "claro-tv",
              variant: "nao_disponivel",
              quantity: "1",
            },
          ],
        },
      },
    });
  }
}

// Enhanced Ecommerce: 3 - Checkout
function* handleTrackCallbackRechargeCheckout(action) {
  const { pathname } = action.payload;
  if (pathname === "/recarga/pagamento-cvv") {
    const isScheduledRecharge = yield select(isScheduled);
    if (isScheduledRecharge) {
      const rechargeValue = yield select(getRechargeValue);
      yield call(tracker, {
        event: "checkout",
        eventCategory: "claro:recarga-pre",
        eventAction: "enhanced-ecommerce",
        eventLabel: "checkout:pagamento",
        ecommerce: {
          checkout: {
            actionField: { step: "pagamento" },
            products: [
              {
                name: createProductName(rechargeValue.value),
                id: rechargeValue.id,
                price: formatProductValue(rechargeValue.value),
                brand: "claro",
                category: "claro-movel",
                variant: "recarga-programada",
                quantity: "1",
              },
            ],
          },
        },
      });
    } else {
      const rechargeValue = yield select(getRechargeValue);
      yield call(tracker, {
        event: "checkout",
        eventCategory: "claro:recarga-pre",
        eventAction: "enhanced-ecommerce",
        eventLabel: "checkout:pagamento",
        ecommerce: {
          checkout: {
            actionField: { step: "pagamento" },
            products: [
              {
                name: createProductName(rechargeValue.value),
                id: rechargeValue.id,
                price: formatProductValue(rechargeValue.value),
                brand: "claro",
                category: "claro-movel",
                variant: "recarga-direta",
                quantity: "1",
              },
            ],
          },
        },
      });
    }
  }
}

function* handleTrackCallbackRechargeTVCheckout(action) {
  const { pathname } = action.payload;
  if (pathname === "/tv/pagamento") {
    const product = yield select(getProduct);
    yield call(tracker, {
      event: "checkout",
      eventCategory: "claro:recarga-pre",
      eventAction: "enhanced-ecommerce",
      eventLabel: "checkout:pagamento",
      ecommerce: {
        checkout: {
          actionField: { step: "pagamento" },
          products: [
            {
              name: createProductName(product.plan.value),
              id: product.plan.id,
              price: formatProductValue(product.plan.value),
              brand: "claro",
              category: "claro-tv",
              variant: "recarga-direta",
              quantity: "1",
            },
          ],
        },
      },
    });
  }
}

// Enhanced Ecommerce: 4 - Purchase
function* handleTrackCallbackRechargePurchase() {
  const rechargeValue = yield select(getRechargeValue);

  yield call(tracker, {
    event: "purchase",
    eventCategory: "claro:recarga-pre",
    eventAction: "enhanced-ecommerce",
    eventLabel: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: rechargeValue.id,
          affiliation: "claro-movel",
          revenue: formatProductValue(rechargeValue.value),
          coupon: "nao_disponivel",
        },
        products: [
          {
            name: createProductName(rechargeValue.value),
            id: rechargeValue.id,
            price: formatProductValue(rechargeValue.value),
            brand: "claro",
            category: "claro-movel",
            variant: "recarga-direta",
            quantity: "1",
            coupon: "nao_disponivel",
          },
        ],
      },
    },
  });
}

function* handleTrackCallbackScheduledRechargePurchase() {
  const rechargeValue = yield select(getRechargeValue);

  yield call(tracker, {
    event: "purchase",
    eventCategory: "enhanced-ecommerce",
    eventAction: "purchase",
    eventLabel: "claro:recarga-pre",
    ecommerce: {
      purchase: {
        actionField: {
          id: rechargeValue.id,
          affiliation: "claro-movel",
          revenue: formatProductValue(rechargeValue.value),
          coupon: "nao_disponivel",
        },
        products: [
          {
            name: createProductName(rechargeValue.value),
            id: rechargeValue.id,
            price: formatProductValue(rechargeValue.value),
            brand: "claro",
            category: "claro-movel",
            variant: "recarga-programada",
            quantity: "1",
            coupon: "nao_disponivel",
          },
        ],
      },
    },
  });
}

function* handleTrackCallbackRechargeTVPurchase() {
  const product = yield select(getProduct);
  yield call(tracker, {
    event: "purchase",
    eventCategory: "enhanced-ecommerce",
    eventAction: "purchase",
    eventLabel: "claro:recarga-pre",
    ecommerce: {
      purchase: {
        actionField: {
          id: "",
          affiliation: "claro-tv",
          revenue: formatProductValue(product.value),
          coupon: "nao_disponivel",
        },
        products: [
          {
            name: createProductName(product.plan.value),
            id: product.plan.id,
            price: formatProductValue(product.plan.value),
            brand: "claro",
            category: "claro-tv",
            variant: "recarga-direta",
            quantity: "1",
            coupon: "nao_disponivel",
          },
        ],
      },
    },
  });
}

export {
  handleTrackViewPage,
  handleTrackClickSendButton,
  handleTrackTypeConfirmationCode,
  handleTrackAccessPage,
  handleTrackClickScheduledNowButton,
  handleTrackCallbackScheduledRechargesSuccess,
  handleTrackCallbackScheduledRechargesErro,
  handleTrackCallbackRechargeProductDetail,
  handleTrackCallbackRechargeTVProductDetail,
  handleTrackCallbackRechargeAddToCard,
  handleTrackCallbackRechargeTVAddToCard,
  handleTrackCallbackRechargeCheckout,
  handleTrackCallbackRechargeTVCheckout,
  handleTrackCallbackRechargePurchase,
  handleTrackCallbackScheduledRechargePurchase,
  handleTrackCallbackRechargeTVPurchase,
  handleTrackClickBanner,
  handleTrackCallbackProgramRecahargeSuccess,
  handleTrackCallbackProgramRecahargeError,
  handleTrackSelectRechargeValue,
  handleTrackClickFinishRecharge,
};
