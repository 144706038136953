import { BRANDS_ADD, RESET_STATE } from "../constants/actionTypes";

const INITIAL_STATE = {
  brands: {},
};

const applyAddBrands = (state, action) => ({
  ...state,
  brands: action.payload,
});

const brandReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BRANDS_ADD:
      return applyAddBrands(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default brandReducer;
