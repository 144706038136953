import menuPaths from "../enum/menuPaths";

const isLoadingVisible = ({ uiState }) => uiState.loading.isVisible;

const isModalVisible = ({ uiState }) => !uiState.loading.isVisible && uiState.modal.isVisible;

const getCurrentTarget = ({ uiState }) => uiState.modal.currentTarget;

const getCurrentPath = ({ uiState }) => uiState.menu.currentPath;

const isRechargePath = ({ uiState }) => uiState.menu.currentPath === menuPaths.RECHARGE;

const isCardsPath = ({ uiState }) => uiState.menu.currentPath === menuPaths.CARDS;

const getSnackbarState = ({ uiState }) => uiState.snackbar;

const isDrawerOpen = ({ uiState }) => uiState.drawer.isOpen;

const isDrawerRecargaOpen = ({ uiState }) =>
  uiState.drawer.isOpen && uiState.drawer.channelPath === "recarga";

const isDrawerMinhaClaroOpen = ({ uiState }) =>
  uiState.drawer.isOpen && ["minha-claro", "claro-vip"].includes(uiState.drawer.channelPath);

const getMedia = ({ uiState }) => uiState.media;

const getTargetBox = ({ uiState }) => uiState.targetBox.targetBox;

export {
  isLoadingVisible,
  isModalVisible,
  getSnackbarState,
  getCurrentTarget,
  getCurrentPath,
  isRechargePath,
  isCardsPath,
  isDrawerOpen,
  isDrawerRecargaOpen,
  isDrawerMinhaClaroOpen,
  getMedia,
  getTargetBox,
};
