const modals = {
  ADD_CARD_SUCCESS: "add-card-success",
  REMOVE_CARD: "remove-card",
  TV_LOGOUT: "tv-logout",
  TV_BANNER: "tv-banner",
  LOGOUT: "logout",
  EXPRESS_VALUE: "express-value",
  EXPRESS_DATE: "express-date",
  EXPRESS_CARD: "express-card",
  DELETE_SCHEDULED_RECHARGE: "delete-scheduled-recharge",
  DELETE_PAYPAL_ACCOUNT: "delete-paypal-account",
  INVALID_AUTHENTICATION_TOKEN: "invalid-authentication-token",
  REMOVE_RECIPIENT: "remove-recipient",
  EDIT_RECIPIENT: "edit-recipient",
  NO_LIMIT: "no-limit",
  PAYMENT_NOT_IMPLEMENTED: "payment-not-implemented",
};

export default modals;
