import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Wrapper = ({ children }) => <div className="wrapper">{children}</div>;

Wrapper.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Wrapper;
