import React, { Component } from "react";

const asyncComponent = getComponent => {
  class AsyncComponent extends Component {
    state = {
      component: null,
    };

    async componentDidMount() {
      const { default: component } = await getComponent();

      this.setState({ component });
    }

    render() {
      return this.state.component ? <this.state.component {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
