import { stringify } from "qs";
import storage from "local-storage-fallback";

import { eldoradoClient, privateClient } from "./client";

const requestTokenization = (pan, month, year) =>
  eldoradoClient.post("/v1/cc", stringify({ pan, month, year, partner: "CLARO" }));

const requestNewPaymentMethod = data =>
  privateClient.post(
    `/customers/${storage.getItem("identifier")}/payment-methods`,
    JSON.stringify(data)
  );

const removePaymentMethod = (type, token) =>
  privateClient.delete(
    `/customers/${storage.getItem("identifier")}/payment-methods/${type}-${token}`
  );

const requestPaypalAuthorize = data =>
  privateClient.post(
    `/customers/${storage.getItem("identifier")}/payment-methods/paypal/authorize`,
    JSON.stringify(data)
  );

const requestPaypalCreateAccount = data =>
  privateClient.post(
    `/customers/${storage.getItem("identifier")}/payment-methods/paypal/accounts`,
    JSON.stringify(data)
  );

const requestPaypalDeleteAccount = billingAgreementId =>
  privateClient.delete(
    `/customers/${storage.getItem(
      "identifier"
    )}/payment-methods/paypal/accounts/${billingAgreementId}`
  );

export {
  requestTokenization,
  requestNewPaymentMethod,
  removePaymentMethod,
  requestPaypalAuthorize,
  requestPaypalCreateAccount,
  requestPaypalDeleteAccount,
};
