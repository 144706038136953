import { RECHARGE_RESUME_ADD, RESET_RECHARGE_RESUME } from "../constants/actionTypes";

const doSaveRechargeResume = (rechargeValue, rechargeBonus, nextScheduleDate) => ({
  type: RECHARGE_RESUME_ADD,
  payload: { rechargeValue, rechargeBonus, nextScheduleDate },
});

const doResetRechargeResume = () => ({
  type: RESET_RECHARGE_RESUME,
});

export { doSaveRechargeResume, doResetRechargeResume };
