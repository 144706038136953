const CustomerGoVisaStates = {
  /** aguardando uma recarga */
  IDLE: "idle",
  /** state posterior a uma recarga com cartão visa */
  PRE_SELECTED: "preselected",
  /** cartão apto para ser submetido ao processo de inscrição */
  APROVED: "aproved",
  /** usuário participa da promoção */
  SUBSCRIBED: "subscribed",
};

export default CustomerGoVisaStates;
