import { call, put } from "redux-saga/effects";

import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import { deleteCustomer } from "../api/customer";

function* handleDeleteCustomer(action) {
  yield put(doShowLoading());

  try {
    const { identifier } = action;
    yield call(deleteCustomer, identifier);
  } catch (e) {
    yield put(doShowError("Erro ao excluir o customer.", e));
  } finally {
    yield put(doHideLoading());
  }
}

export { handleDeleteCustomer };
