import storage from "local-storage-fallback";
import { privateClient } from "./client";

const fetchPaymentMethods = () =>
  privateClient.get(`/customers/${storage.getItem("identifier")}/payment-methods`);

const fetchRechargeBalance = () =>
  privateClient.get(`/customers/${storage.getItem("identifier")}/recharge/balance`);

const requestEventAbandonedCart = data =>
  privateClient.post(
    `/customer/${storage.getItem("identifier")}/events/checkout`,
    JSON.stringify(data)
  );

const fetchCustomer = identifier => privateClient.get(`/customers/${identifier}`);

const deleteCustomer = identifier => privateClient.delete(`/customers/${identifier}`);

export {
  fetchPaymentMethods,
  fetchRechargeBalance,
  fetchCustomer,
  requestEventAbandonedCart,
  deleteCustomer,
};
