import { FEATURE_ADD, RESET_STATE } from "../constants/actionTypes";

const INITIAL_STATE = {
  pix: false,
  pix_filter: false,
  url_without_channel: false,
  recharge_for_others: false,
  value_page_without_banner: false,
  OTPModal: false,
  upsell_recharge: false,
  value_page_without_bonus: false,
  value_card_with_no_expiration_date: false,
  ddd_upsell: false,
  channel_without_schedule_modal: false,
  channel_without_footer: false,
  ddd_enable_data_bundle: false,
};

const apply = (state, action) => ({
  ...state,
  [action.payload.featureName]: action.payload.status,
});

const featureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEATURE_ADD:
      return apply(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default featureReducer;
