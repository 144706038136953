import md5 from "md5";

import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  RESEND_SMS_STATUS_CHANGE,
  IDENTIFIER_ADD,
  ENCRYPTED_LOGIN_UNSUCCESSFUL,
} from "../constants/actionTypes";
import smsStatuses from "../enum/smsStatuses";
import channels from "../enum/channels";

const INITIAL_STATE = {
  token: "",
  identifier: "",
  smsStatus: smsStatuses.DONE,
  gaUserId: "",
  segment: "",
  channel: channels.recarga,
  channelPath: "recarga",
  partnerExternalId: "",
};

const applySuccessLogin = (state, action) => {
  const { token, identifier, segment, channel, partnerExternalId } = action.payload;
  const gaUserId = md5(identifier);
  return { ...state, token, identifier, gaUserId, segment, channel, partnerExternalId };
};

const applyUnsuccessfullEncryptedLogin = (state, action) => {
  const { channel, channelPath } = action.payload;
  return { ...state, channel, channelPath };
};

const applyChangeResendSMSStatus = (state, action) => {
  const { smsStatus } = action.payload;
  return { ...state, smsStatus };
};

const applyAddIdentifier = (state, action) => {
  const { identifier } = action.payload;
  return { ...state, identifier };
};

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IDENTIFIER_ADD:
      return applyAddIdentifier(state, action);
    case LOGIN_SUCCESS:
      return applySuccessLogin(state, action);
    case RESEND_SMS_STATUS_CHANGE:
      return applyChangeResendSMSStatus(state, action);
    case ENCRYPTED_LOGIN_UNSUCCESSFUL:
      return applyUnsuccessfullEncryptedLogin(state, action);
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default sessionReducer;
