import { createGlobalStyle } from "styled-components";
import ConvertToEms, { rem, em } from "convert-to-ems";
import amxRegular from "../fonts/AMX/AMX-Regular.ttf";
import amxMedium from "../fonts/AMX/AMX-Medium.ttf";
import amxBold from "../fonts/AMX/AMX-Bold.ttf";

new ConvertToEms().config(16);
const BASE_FONT_SIZE = 16;
export const STYLES = {
  baseFontSize: BASE_FONT_SIZE,
  baseFontFamily: "'AMX', 'Trebuchet MS', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  baseColor: "#fff",
  tinyFont: em(29),
  contentSpaceBetween: "space-between",
  contentCenter: "center",
  contentFlexStart: "flex-start",
  contentFlexEnd: "flex-end",
  baseBackgroundColor: "#bb1722",
  backgroundColor: "#d52b1e",
  headerBrandsHeight: rem(32),
  headerBrandsMarginLeft: rem(20),
  headerMarginLeft: rem(15),
  breakpoints: {
    tiny: "min-width: 320px",
    small: "min-width: 450px",
    medium: "min-width: 768px",
    large: "min-width: 1200px",
    xlarge: "min-width: 1366px",
    mobile: "max-width: 767px",
    tinyMobile: "max-width: 320px",
    minor: "min-width: 361px",
    smallHomeScreen: "(min-width: 320px) and (max-width: 360px)",
    largeHomeScreen: "(min-width: 361px) and (max-width: 768px)",
    tablet: "max-width: 1024px",
    ipad: "(max-width: 1024px) and (min-width: 767px)",
    mobile_second: "max-width: 461px",
    tablet_and_desktop: "min-width: 612px",
    tablet_second: "(max-width: 767px) and (min-width: 550px)",
  },
};

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'AMX';
    src: url(${amxRegular}) format('opentype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'AMX';
    src: url(${amxMedium}) format('opentype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'AMX';
    src: url(${amxBold}) format('opentype');
    font-style: normal;
    font-weight: bold;
  }
`;

export const GlobalStyle = createGlobalStyle`

  body{
    background-color: #f4f6fb;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #000;
  }

`;
