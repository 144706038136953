import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";

import { doShowLoading, doHideLoading, doShowSnackbar } from "../actions/ui";
import { requestTokenization } from "../api/paymentMethod";
import { requestTvPayment } from "../api/tvRecharge";
import { doNewTvRechargeSuccess, doNewTvRechargeError } from "../actions/tvRecharge";
import paymentMethods from "../enum/paymentMethods";
import translateError from "../lib/thundercatsErrorMessages";

const buidlRequestBody = (dataToken, cvv, newTvRecharge) => {
  const { product, target } = newTvRecharge;

  const payload = {
    paymentMethod: {
      data: {
        cvv,
        token: dataToken.card.key,
      },
      type: paymentMethods.CREDITO,
    },
    product: {
      id: product.plan.id,
      value: product.plan.price,
    },
    target,
  };

  return payload;
};

const hasErrors = reasonError => {
  if (reasonError === null || typeof reasonError === "undefined") return false;

  const { errors } = reasonError;
  if (errors.length <= 0 || reasonError.length <= 0) return false;

  return true;
};

function* handleTvPaymentRequest(action) {
  yield put(doShowLoading());

  const { newTvRecharge, pan, month, year, cvv } = action.payload;
  let dataToken = null;

  try {
    const { data } = yield call(requestTokenization, pan, month, year);

    if (!data.result) {
      throw new Error("No data result");
    } else if (!data.result.resultSuccess) {
      throw new Error(data.result.resultMessage);
    }

    dataToken = data;
  } catch (e) {
    yield put(doHideLoading());
    yield put(doShowSnackbar("error", "Cartão inválido. Por favor, revise os dados do cartão."));
  }

  if (dataToken) {
    try {
      const rechargeResult = yield call(
        requestTvPayment,
        buidlRequestBody(dataToken, cvv, newTvRecharge)
      );
      const { reason_error: reasonError } = rechargeResult.data;

      if (rechargeResult.status === 200 && !hasErrors(reasonError)) {
        // variação de callback dos TC sync e async
        yield put(doNewTvRechargeSuccess());
        yield put(push("sucesso"));
        return;
      }

      let message = null;
      if (reasonError !== undefined) {
        message = translateError(reasonError.errors);
      }

      yield put(doNewTvRechargeError(message));
      yield put(push("falha"));
    } catch (e) {
      console.log(e);
      yield put(doNewTvRechargeError());
      yield put(push("falha"));
    } finally {
      yield put(doHideLoading());
    }
  }
}

export { handleTvPaymentRequest };
