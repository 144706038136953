import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import { getMedia } from "../../../../selectors/ui";

const PublicRoute = ({ component: Component, exact, mobile: MobileComponent, media, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (media && media.mobile) {
        return <MobileComponent {...props} />;
      }
      return <Component {...props} />;
    }}
  />
);

PublicRoute.propTypes = {
  media: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
  mobile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  media: getMedia(state),
});

export default connect(mapStateToProps)(PublicRoute);
