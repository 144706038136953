import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import { getMedia } from "../../../../selectors/ui";
import { isSessionLoggedIn } from "../../../../selectors/session";

const AuthRoute = ({
  component: Component,
  exact,
  mobile: MobileComponent,
  media,
  location,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (media && media.mobile) {
          return <MobileComponent {...props} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

AuthRoute.propTypes = {
  media: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
  mobile: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  media: getMedia(state),
  isAuthenticated: isSessionLoggedIn(state),
});

export default connect(mapStateToProps)(AuthRoute);
