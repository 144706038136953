import axios from "axios";
import { getChannel } from "../lib/utils";

const getDefaultClient = baseUrl => {
  const client = axios.create({
    baseURL: baseUrl,
    headers: { "Content-Type": "application/json" },
  });

  client.interceptors.request.use(
    config => {
      const channel = getChannel();

      if (channel) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Channel = channel;
      }

      return config;
    },
    error => Promise.reject(error)
  );

  return client;
};

const getPrivateClient = baseUrl => {
  const client = getDefaultClient(baseUrl);

  client.interceptors.request.use(
    config => {
      const token = sessionStorage.getItem("token");
      const sourceIp = sessionStorage.getItem("sourceIp");
      const countryOrigin = sessionStorage.getItem("countryOrigin");

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `claro ${token}`;
      }
      if (sourceIp) {
        // eslint-disable-next-line no-param-reassign
        config.headers.SourceIP = sourceIp;
      }
      if (countryOrigin) {
        // eslint-disable-next-line no-param-reassign
        config.headers.CountryOrigin = countryOrigin;
      }

      return config;
    },
    error => Promise.reject(error)
  );

  return client;
};

const getEldoradoClient = () =>
  axios.create({
    baseURL: process.env.REACT_APP_ELDORADO_API_URL,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

const getBrandsClient = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BRANDS_API_URL,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

const publicClient = getDefaultClient(process.env.REACT_APP_RECARGA_API_URL);

const privateClient = getPrivateClient(process.env.REACT_APP_RECARGA_API_URL);

const eldoradoClient = getEldoradoClient();

const brandsClient = getBrandsClient();

const publicTvClient = getDefaultClient(process.env.REACT_APP_TV_API_URL);

const privateTvClient = getPrivateClient(process.env.REACT_APP_TV_API_URL);

export {
  publicClient,
  privateClient,
  eldoradoClient,
  publicTvClient,
  privateTvClient,
  brandsClient,
};
