import storage from "local-storage-fallback";

const loadState = () => {
  try {
    const serializedState = storage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    storage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export { loadState, saveState };
