import {
  SMS_REQUEST,
  RESEND_SMS_REQUEST,
  RESEND_SMS_STATUS_CHANGE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  ENCRYPTED_LOGIN_UNSUCCESSFUL,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  CPF_LOGIN_REQUEST,
  ENCRYPTED_LOGIN_REQUEST,
  IDENTIFIER_ADD,
} from "../constants/actionTypes";

const doRequestSMS = (msisdn, target, origin) => ({
  type: SMS_REQUEST,
  payload: { msisdn, target, origin },
});

const doRequestResendSMS = (msisdn, showLoading = false) => ({
  type: RESEND_SMS_REQUEST,
  payload: { msisdn, showLoading },
});

const doChangeResendSMSStatus = smsStatus => ({
  type: RESEND_SMS_STATUS_CHANGE,
  payload: { smsStatus },
});

const doRequestLogin = (msisdn, data, type, channel, origin, loading = false) => ({
  type: LOGIN_REQUEST,
  payload: { msisdn, data, type, channel, origin, loading },
});

const doSuccessLogin = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

const doUnsuccessfulEncryptedLogin = payload => ({
  type: ENCRYPTED_LOGIN_UNSUCCESSFUL,
  payload,
});

const doAddIdentifier = identifier => ({
  type: IDENTIFIER_ADD,
  payload: { identifier },
});

const doErrorLogin = () => ({
  type: LOGIN_ERROR,
});

const doRequestCpfLogin = (identifier, type, channel, origin, loading = false) => ({
  type: CPF_LOGIN_REQUEST,
  payload: {
    type,
    data: {
      identifier,
      canal: channel,
    },
    origin,
    loading,
  },
});

const doRequestEncryptedLogin = (data, type, channel, origin) => ({
  type: ENCRYPTED_LOGIN_REQUEST,
  payload: { data, type, channel, origin },
});

const doRequestLogout = () => ({ type: LOGOUT_REQUEST });

export {
  doRequestSMS,
  doRequestResendSMS,
  doChangeResendSMSStatus,
  doRequestLogin,
  doSuccessLogin,
  doUnsuccessfulEncryptedLogin,
  doErrorLogin,
  doRequestLogout,
  doRequestCpfLogin,
  doRequestEncryptedLogin,
  doAddIdentifier,
};
