import { v4 as uuidv4 } from "uuid";

import {
  REQUEST_RECHARGE_BALANCE,
  REQUEST_RECHARGE_BALANCE_SUCCESS,
  REQUEST_RECHARGE_BALANCE_FAIL,
  RESET_STATE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isBalanceLoading: false,
  hasBalanceError: false,
  balance: null,
};

const generateBalanceBonusKey = balance => ({
  ...balance,
  bonus: balance.bonus.map(bonus => ({
    ...bonus,
    key: uuidv4(),
  })),
});

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_RECHARGE_BALANCE:
      return {
        ...state,
        isBalanceLoading: true,
        hasBalanceError: false,
      };
    case REQUEST_RECHARGE_BALANCE_SUCCESS:
      return {
        ...state,
        isBalanceLoading: false,
        balance: generateBalanceBonusKey(action.balance),
      };
    case REQUEST_RECHARGE_BALANCE_FAIL:
      return {
        ...state,
        isBalanceLoading: false,
        hasBalanceError: true,
      };
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default homeReducer;
