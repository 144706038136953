import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { isLoadingVisible } from "../../../selectors/ui";
import LoadingWithIcon from "../../shared/Loading/LoadingIcon";

const LoadingIcon = ({ isVisible }) => (isVisible ? <LoadingWithIcon /> : null);
LoadingIcon.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isVisible: isLoadingVisible(state),
});

export default connect(mapStateToProps)(LoadingIcon);
