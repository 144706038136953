import claroControleInverseImg from "../images/ClaroControleInverse.png";
import claroWhtasImg from "../images/ClaroWhatsapp.png";
import claroYoutubeImg from "../images/ClaroYoutube.png";
import claroPlusImg from "../images/plus_yellow.svg";

export const PROPS_UPSELL = [
  {
    type: "prezao",
    conditions: {
      values: [1500],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
        "-CLARO_MIGRACAO_OI",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      isPrezao: [true],
      offerValue: [2000, 3000, 3500],
      ddds: ["ALL"],
    },
  },
  {
    type: "prezao",
    conditions: {
      values: [2000, 2500],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
        "-CLARO_MIGRACAO_OI",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      isPrezao: [true],
      offerValue: [3000, 3500],
      ddds: ["ALL"],
    },
  },
  {
    type: "prezao",
    conditions: {
      values: [3000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
        "-CLARO_MIGRACAO_OI",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      isPrezao: [true],
      offerValue: [3500],
      ddds: ["ALL"],
    },
  },
  {
    type: "normal",
    conditions: {
      values: [2000, 2500, 3000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["ACTIVATED"],
      ddds: ["ALL"],
      isPrezao: [false],
    },
  },
  {
    type: "controle_custom_1",
    conditions: {
      values: [3000, 3500, 5000, 10000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: ["42", "53", "96"],
      isPrezao: [false],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_CUSTOM1_URL,
    images: {
      headerImage: claroControleInverseImg,
      claroWhtasImg: { src: claroWhtasImg, alt: "claroWhtasImg" },
      claroYoutubeImg: { src: claroYoutubeImg, alt: "claroYoutubeImg" },
      claroPlusImg: { src: claroPlusImg, alt: "claroSomaImg" },
    },
    rechargeValue: "34,90",
    bonus: 12,
  },
  {
    type: "controle_custom_1",
    conditions: {
      values: [5000, 10000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: ["42", "53", "96"],
      isPrezao: [true],
      offerValue: [2000, 3000, 3500],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_CUSTOM1_URL,
    images: {
      headerImage: claroControleInverseImg,
      claroWhtasImg: { src: claroWhtasImg, alt: "claroWhtasImg" },
      claroYoutubeImg: { src: claroYoutubeImg, alt: "claroYoutubeImg" },
      claroPlusImg: { src: claroPlusImg, alt: "claroSomaImg" },
    },
    rechargeValue: "34,90",
    bonus: 12,
  },
  {
    type: "controle_custom_2",
    conditions: {
      values: [4000],
      includedChannels: [
        "ALL",
        "-CLARO_FLEX",
        "-CLARO_FLEX_INAD",
        "-CLARO_CONTROLE_FACIL",
        "-CLARO_CONTROLE_WEB",
      ],
      excludedSegments: ["CONTROLE"],
      customerStatus: ["CREATED", "ACTIVATED"],
      ddds: ["ALL"],
      isPrezao: [true, false],
      offerValue: [2000, 3000, 3500],
    },
    redirectUrl: process.env.REACT_APP_UPSELLMODAL_CLAROCONTROLE_CUSTOM2_URL,
    images: {
      headerImage: claroControleInverseImg,
      claroWhtasImg: { src: claroWhtasImg, alt: "claroWhtasImg" },
      claroYoutubeImg: { src: claroYoutubeImg, alt: "claroYoutubeImg" },
      claroPlusImg: { src: claroPlusImg, alt: "claroSomaImg" },
    },
    rechargeValue: "49,90",
    bonus: 15,
  },
];
