import {
  NEW_RECHARGE_CHANGE,
  NEW_RECHARGE_REQUEST,
  NEW_RECHARGE_RESET,
  RECHARGES_FETCH,
  RECHARGES_ADD,
  RECHARGES_SUCCESS,
  RECHARGES_ERROR,
  EXPRESS_RECHARGE_CHANGE,
  NEW_NO_CREDIT_RECHARGE_REQUEST,
  NEW_PIX_ENCRYPTED_RECHARGE_REQUEST,
} from "../constants/actionTypes";
import cardColors from "../enum/cardColors";
import paymentMethodsEnum from "../enum/paymentMethods";

const doResetNewRecharge = () => ({
  type: NEW_RECHARGE_RESET,
});

const doSelectCreditCard = (
  index,
  token,
  lastDigits,
  brandName,
  confirmed,
  type = paymentMethodsEnum.CREDITO,
  label = "Cartão de Crédito",
  bin = ""
) => ({
  type: NEW_RECHARGE_CHANGE,
  payload: {
    key: "paymentMethod",
    value: {
      type,
      data: {
        token,
        lastDigits,
        brandName,
        confirmed,
        bin,
      },
      label,
      color: cardColors[index],
    },
  },
});

const doUnselectCreditCard = () => ({
  type: NEW_RECHARGE_CHANGE,
  payload: {
    key: "paymentMethod",
    value: {},
  },
});

const doUnselectCreditCardWithType = (type, label) => ({
  type: NEW_RECHARGE_CHANGE,
  payload: {
    key: "paymentMethod",
    value: {
      type,
      data: {},
      label,
    },
  },
});

const doSelectPaypalAccount = (billingAgreementId, email) => ({
  type: NEW_RECHARGE_CHANGE,
  payload: {
    key: "paymentMethod",
    value: {
      type: paymentMethodsEnum.PAYPAL,
      data: {
        billingAgreementId,
        email,
      },
    },
  },
});

const doChangeNewRecharge = (key, value) => ({
  type: NEW_RECHARGE_CHANGE,
  payload: {
    key,
    value,
  },
});

const doRequestNewRecharge = payload => ({
  type: NEW_RECHARGE_REQUEST,
  payload,
});

const doRequestNewNoCreditRechargeWithCardTokenized = (newRecharge, cvv) => ({
  type: NEW_NO_CREDIT_RECHARGE_REQUEST,
  isCardTokenized: true,
  payload: {
    newRecharge,
    cvv,
  },
});

const doRequestNewNoCreditRecharge = (
  newRecharge,
  pan,
  month,
  year,
  cvv,
  brand,
  type,
  key,
  lastDigits,
  data3ds
) => ({
  type: NEW_NO_CREDIT_RECHARGE_REQUEST,
  isCardTokenized: false,
  payload: {
    newRecharge,
    pan,
    month,
    year,
    cvv,
    brand,
    type,

    // Eldorado Bridge
    key,
    lastDigits,
    data3ds,
  },
});

const normalizeRechargeValue = rechargeValue => {
  const { data, ...updatedRechargeValue } = rechargeValue;

  return updatedRechargeValue;
};

const normalizePaymentMethodPaypal = paymentMethod => {
  const { billingAgreementId, ...restData } = paymentMethod.data;

  return {
    ...paymentMethod,
    data: { ...restData, billing_agreement_id: billingAgreementId },
  };
};

const doRequestNewRechargeWithPaypal = newRecharge => {
  const { rechargeValue, paymentMethod } = newRecharge;
  const payload = {
    ...newRecharge,
    rechargeValue: normalizeRechargeValue(rechargeValue),
    paymentMethod: normalizePaymentMethodPaypal(paymentMethod),
  };

  return {
    type: NEW_RECHARGE_REQUEST,
    payload,
  };
};

const doRequestNewRechargeWithGPay = (newRecharge, paymentMethod) => {
  const payload = {
    ...newRecharge,
    paymentMethod,
  };

  return {
    type: NEW_RECHARGE_REQUEST,
    payload,
  };
};

const doFetchRecharges = () => ({
  type: RECHARGES_FETCH,
});

const doSaveRecharges = recharges => ({
  type: RECHARGES_ADD,
  payload: recharges,
});

const doRechargeSuccess = (paymentMethod, customerInfo, paymentInfo, reloadInfo) => ({
  type: RECHARGES_SUCCESS,
  payload: {
    paymentMethod,
    customerInfo,
    paymentInfo,
    reloadInfo,
  },
});

const doRechargeError = () => ({
  type: RECHARGES_ERROR,
});

const doChangeExpressRecharge = (key, value) => ({
  type: EXPRESS_RECHARGE_CHANGE,
  payload: {
    key,
    value,
  },
});

const doRequestNewPixEncryptedRecharge = data => ({
  type: NEW_PIX_ENCRYPTED_RECHARGE_REQUEST,
  payload: { data },
});

export {
  doChangeNewRecharge,
  doRequestNewRecharge,
  doRequestNewRechargeWithPaypal,
  doRequestNewRechargeWithGPay,
  doResetNewRecharge,
  doFetchRecharges,
  doSaveRecharges,
  doRechargeSuccess,
  doRechargeError,
  doChangeExpressRecharge,
  doSelectCreditCard,
  doUnselectCreditCard,
  doUnselectCreditCardWithType,
  doSelectPaypalAccount,
  doRequestNewNoCreditRecharge,
  doRequestNewNoCreditRechargeWithCardTokenized,
  doRequestNewPixEncryptedRecharge,
};
