const isSessionLoggedIn = ({ sessionState }) =>
  sessionState.token !== null &&
  sessionState.token !== "" &&
  sessionState.identifier !== null &&
  sessionState.identifier !== "";

const getIdentifier = ({ sessionState }) => sessionState.identifier;

const getSmsStatus = ({ sessionState }) => sessionState.smsStatus;

const getGaUserId = ({ sessionState }) => sessionState.gaUserId;

const getSegment = ({ sessionState }) => sessionState.segment;

const getChannel = ({ sessionState }) => [sessionState.channelPath, sessionState.channel];

export { isSessionLoggedIn, getIdentifier, getSmsStatus, getGaUserId, getSegment, getChannel };
