import { call, put } from "redux-saga/effects";

import { fetchFeature, fetchPublicFeature } from "../api/feature";
import { doSaveFeatures } from "../actions/feature";

function* handleFetchFeature() {
  try {
    const pix = yield call(fetchFeature, "pix");
    yield put(doSaveFeatures("pix", pix.data.enable));

    const pixFilter = yield call(fetchFeature, "pix_filter");
    yield put(doSaveFeatures("pix_filter", pixFilter.data.enable));

    const upsellRecharge = yield call(fetchFeature, "upsell_recharge");
    yield put(doSaveFeatures("upsell_recharge", upsellRecharge.data.enable));

    const urlWithoutChannel = yield call(fetchFeature, "url_without_channel");
    yield put(doSaveFeatures("url_without_channel", urlWithoutChannel.data.enable));

    const rechargeForOthers = yield call(fetchFeature, "recharge_for_others");
    yield put(doSaveFeatures("recharge_for_others", rechargeForOthers.data.enable));

    const valuePageWithoutBanner = yield call(fetchFeature, "value_page_without_banner");
    yield put(doSaveFeatures("value_page_without_banner", valuePageWithoutBanner.data.enable));

    const otpModal = yield call(fetchFeature, "OTPModal");
    yield put(doSaveFeatures("OTPModal", otpModal.data.enable));

    const valuePageWithoutBonus = yield call(fetchFeature, "value_page_without_bonus");
    yield put(doSaveFeatures("value_page_without_bonus", valuePageWithoutBonus.data.enable));

    const dddUpsell = yield call(fetchFeature, "ddd_upsell");
    yield put(doSaveFeatures("ddd_upsell", dddUpsell.data.enable));

    const dddEnableDataBundle = yield call(fetchFeature, "ddd_enable_data_bundle");
    yield put(doSaveFeatures("ddd_enable_data_bundle", dddEnableDataBundle.data.enable));

    const channelWithoutScheduleModal = yield call(fetchFeature, "channel_without_schedule_modal");
    yield put(
      doSaveFeatures("channel_without_schedule_modal", channelWithoutScheduleModal.data.enable)
    );

    const channelWithoutFooter = yield call(fetchFeature, "channel_without_footer");
    yield put(doSaveFeatures("channel_without_footer", channelWithoutFooter.data.enable));

    const valueWithNoExpirationDate = yield call(
      fetchFeature,
      "value_card_with_no_expiration_date"
    );
    yield put(
      doSaveFeatures("value_card_with_no_expiration_date", valueWithNoExpirationDate.data.enable)
    );
  } catch (e) {
    console.error("Erro ao recarregar as features.", e);
  }
}

function* handleFetchPublicFeatures() {
  try {
    const publicFeatures = yield call(fetchPublicFeature);

    yield put(doSaveFeatures("url_without_channel", publicFeatures.data.url_without_channel));
  } catch (e) {
    console.log("erros", e);
    console.error("Erro ao recarregar as features públicas.", e);
  }
}

export { handleFetchFeature, handleFetchPublicFeatures };
