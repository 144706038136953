import { TARGET_BOX_SELECT, LOGOUT_REQUEST } from "../../constants/actionTypes";

const INITIAL_STATE = {
  targetBox: "",
};

const applySelectTargetBox = action => ({
  targetBox: action.targetBox,
});

const mediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TARGET_BOX_SELECT:
      return applySelectTargetBox(action);
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default mediaReducer;
