import axios from "axios";

const getGeoInformations = async () => {
  try {
    const response = await axios.get(`https://geolocation-db.com/json/`, { timeout: 3000 });

    const { data } = response;
    return data;
  } catch (e) {
    console.error("error on get geolocation infos", e);
    return null;
  }
};

export { getGeoInformations };
