import PaymentMethodsENUM from "../enum/paymentMethods";
import status from "../enum/paymentStatus";

const getNewRecharge = ({ rechargeState }) => rechargeState.newRecharge;

const getRechargeValue = ({ rechargeState }) => rechargeState.newRecharge.rechargeValue;

const getResumeRecharge = ({ rechargeResumeState }) => rechargeResumeState;

const getTargetMsisdn = ({ rechargeState }) => rechargeState.newRecharge.targetMsisdn;

const getPaymentMethod = ({ rechargeState }) => rechargeState.newRecharge.paymentMethod;

const getInstallments = ({ rechargeState }) => rechargeState.newRecharge.installments;

const isPaymentMethodSet = ({ rechargeState }) =>
  rechargeState.newRecharge.paymentMethod.constructor === Object &&
  Object.keys(rechargeState.newRecharge.paymentMethod).length > 0;

const isPaymentMethodDataSetted = ({ rechargeState }) =>
  rechargeState.newRecharge.paymentMethod.constructor === Object &&
  Object.keys(rechargeState.newRecharge.paymentMethod.data).length > 0;

const isPaymentMethodConfirmed = ({ rechargeState }) =>
  isPaymentMethodSet({ rechargeState }) && rechargeState.newRecharge.paymentMethod.data.confirmed;

const isRechargeValueSet = ({ rechargeState }) =>
  rechargeState.newRecharge.rechargeValue &&
  rechargeState.newRecharge.rechargeValue.constructor === Object &&
  Object.keys(rechargeState.newRecharge.rechargeValue).length > 0;

const isTargetMsisdnSet = ({ rechargeState }) =>
  rechargeState.newRecharge.targetMsisdn.constructor === String &&
  rechargeState.newRecharge.targetMsisdn !== "";

const isScheduled = ({ rechargeState }) => rechargeState.newRecharge.isScheduled;

const getDayLabel = ({ rechargeState }) => rechargeState.newRecharge.dayLabel;

const getDay = ({ rechargeState }) => rechargeState.newRecharge.day;

const getFrequency = ({ rechargeState }) => rechargeState.newRecharge.frequency;

const getRecharges = ({ rechargeState }) => rechargeState.recharges;

const getNumberOfRecharges = ({ rechargeState }) => getRecharges({ rechargeState }).length;

const hasAnyRecharges = ({ rechargeState }) => getNumberOfRecharges({ rechargeState }) >= 1;

const getSomeConfirmedCreditCard = ({ paymentMethodState }) => {
  const paymentsTypeCredit = paymentMethodState.paymentMethods.find(
    paymentMethod => paymentMethod.type === PaymentMethodsENUM.CREDITO
  );

  if (paymentsTypeCredit && paymentsTypeCredit.elements) {
    return paymentsTypeCredit.elements.find(card => card.confirmed);
  }
  return null;
};

const getLatestRecharge = ({ rechargeState }) =>
  rechargeState.recharges
    ? rechargeState.recharges.find(recharge => recharge.status === "ok")
    : undefined;

const getLatestValidPaymentMethod = ({ rechargeState, paymentMethodState }) => {
  const recharges = rechargeState.recharges || [];
  const paymentMethods = paymentMethodState.paymentMethods || [];
  const paymentsTypeCredit = paymentMethods.find(p => p.type === PaymentMethodsENUM.CREDITO);
  const creditPayments =
    paymentsTypeCredit && paymentsTypeCredit.elements ? paymentsTypeCredit.elements : [];
  let lastValidPaymentMethod = null;
  let paymentMethod = null;
  let token = null;

  // find last payment method valid from recharges
  for (let i = 0; i < recharges.length; i += 1) {
    token =
      recharges[i].recharges &&
      recharges[i].recharges.paymentMethod &&
      recharges[i].recharges.paymentMethod.source &&
      recharges[i].recharges.paymentMethod.source.params &&
      recharges[i].recharges.paymentMethod.source.params.token;
    paymentMethod = null;

    for (let x = 0; x < creditPayments.length; x += 1) {
      if (token === creditPayments[x].token) {
        paymentMethod = creditPayments[x];
        break;
      }
    }

    if (paymentMethod && paymentMethod.active) {
      lastValidPaymentMethod = paymentMethod;
      break;
    }
  }

  // if was not found then find the favorite credit card
  if (!lastValidPaymentMethod) {
    lastValidPaymentMethod = creditPayments.find(c => c.favorite && c.active);
  }

  return lastValidPaymentMethod;
};

const getNumberCreditRechargesOnDay = ({ rechargeState }) => {
  const recharges = rechargeState.recharges || [];
  let count = 0;
  const date = new Date();

  recharges.forEach(recharge => {
    const dateRecharge = new Date(recharge.registerDate);

    if (
      dateRecharge.getFullYear() === date.getFullYear() &&
      dateRecharge.getMonth() === date.getMonth() &&
      dateRecharge.getDay() === date.getDay() &&
      recharge.paymentMethod.source.type === "credit" &&
      recharge.status === "ok"
    ) {
      count += 1;
    }
  });
  return count;
};

const getLatestOkRechargePerType = ({ rechargeState }, type) => {
  return rechargeState.recharges
    ? rechargeState.recharges.find(
        recharge => recharge.status === status.OK && type === recharge.paymentMethod.source.type
      )
    : undefined;
};

export {
  getNewRecharge,
  getRechargeValue,
  getTargetMsisdn,
  getRecharges,
  getLatestRecharge,
  getLatestValidPaymentMethod,
  getNumberOfRecharges,
  getPaymentMethod,
  getInstallments,
  isPaymentMethodSet,
  isPaymentMethodDataSetted,
  isPaymentMethodConfirmed,
  isRechargeValueSet,
  isTargetMsisdnSet,
  isScheduled,
  getDayLabel,
  getDay,
  getFrequency,
  hasAnyRecharges,
  getNumberCreditRechargesOnDay,
  getResumeRecharge,
  getLatestOkRechargePerType,
  getSomeConfirmedCreditCard,
};
