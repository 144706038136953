import {
  RECIPIENTS_ADD,
  REQUEST_UPDATE_RECIPIENT_SUCCESS,
  RESET_STATE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  recipients: [],
};

const applyAddRecipients = (state, action) => ({
  ...state,
  recipients: action.payload,
});

const applyUpdateRecipient = (state, action) => {
  const newList = state.recipients.slice() || [];
  const { msisdn, payload } = action;

  for (let i = 0; i < newList.length; i += 1) {
    if (newList[i].msisdn === msisdn) {
      newList[i].msisdn = payload.msisdn;
      newList[i].name = payload.name;
      break;
    }
  }

  return {
    ...state,
    recipients: newList,
  };
};

const recipientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECIPIENTS_ADD:
      return applyAddRecipients(state, action);
    case REQUEST_UPDATE_RECIPIENT_SUCCESS:
      return applyUpdateRecipient(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default recipientReducer;
