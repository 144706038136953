const paymentMethods = {
  CREDITO: "credit",
  DEBITO_CEF: "debit_cef",
  DEBITO: "debit",
  PAYPAL: "paypal",
  MERCADO_PAGO: "mercado_pago",
  GOOGLE_PAY: "google_pay",
  PIX: "pix",
};

export const paymentTypes = {
  credit: "Crédito",
  debit: "Débito",
  debit_cef: "Débito Virtual Caixa",
  pix: "PIX",
};

export default paymentMethods;
