import { applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { routerMiddleware } from "connected-react-router";

import configureStore from "./configureStore";
import history from "../history";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import envs from "../enum/envs";

const sagaMiddleware = createSagaMiddleware();
let middleware;

if (process.env.REACT_APP_NODE_ENV === envs.development) {
  middleware = composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware));
} else {
  middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);
}

const store = configureStore(rootReducer(history), middleware);

sagaMiddleware.run(rootSaga);

export default store;
