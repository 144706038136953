import { OPEN_DRAWER, CLOSE_DRAWER, LOGOUT_REQUEST } from "../../constants/actionTypes";

const INITIAL_STATE = {
  isOpen: false,
  channelPath: "",
};

const openDrawer = action => ({
  isOpen: true,
  channelPath: action.channelPath,
});

const closeDrawer = () => ({
  isOpen: false,
  channelPath: "",
});

const drawerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return openDrawer(action);
    case CLOSE_DRAWER:
      return closeDrawer();
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default drawerReducer;
