import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { connect } from "react-redux";

import { doChangeMedia } from "../../../actions/ui";

const queries = {
  mobile: "(max-width: 767px)",
  tablet: "(max-width: 1024px)",
};

class WindowResize extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", debounce(this.checkMediaQuery, 300));
    this.checkMediaQuery();
  }

  checkMediaQuery = () => {
    const state = {};

    Object.keys(queries).forEach(key => {
      state[key] = window.matchMedia(queries[key]).matches;
    });

    this.props.changeMedia(state);
  };

  render() {
    return null;
  }
}

WindowResize.propTypes = {
  changeMedia: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    changeMedia: doChangeMedia,
  }
)(WindowResize);
