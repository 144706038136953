import {
  RECHARGE_VALUES_ADD,
  LOGIN_SUCCESS,
  RESET_RECHARGE_VALUES,
  RESET_STATE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  rechargeValues: [],
  averageTicket: {},
  loginRechargeValue: "",
};

const applyAddRechargeValues = (state, action) => {
  const { rechargeValues, averageTicket } = action.payload;
  return { ...state, rechargeValues, averageTicket };
};

const applyAddLoginRechargeValue = (state, action) => {
  const { loginRechargeValue } = action.payload;
  return { ...state, loginRechargeValue };
};

const applyResetRechargeValues = state => ({
  ...state,
  rechargeValues: INITIAL_STATE.rechargeValues,
});

const rechargeValueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECHARGE_VALUES_ADD:
      return applyAddRechargeValues(state, action);
    case RESET_RECHARGE_VALUES:
      return applyResetRechargeValues(state);
    case LOGIN_SUCCESS:
      return applyAddLoginRechargeValue(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default rechargeValueReducer;
