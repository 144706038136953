import { call, put, delay, select, putResolve } from "redux-saga/effects";
import { push } from "connected-react-router";

import {
  doSaveScheduledRecharges,
  doFilterScheduledRecharges,
  doSuccessUpdateScheduledRecharge,
  doFailureUpdateScheduledRecharge,
  doResetEditableScheduledRecharge,
  doScheduledRechargeSuccess,
  doScheduledRechargeError,
  doSaveHistoricScheduledRecharges,
} from "../actions/scheduledRecharge";
import { doShowLoading, doHideLoading, doShowError, doShowSnackbar } from "../actions/ui";
import {
  fetchScheduledRecharges,
  requestUpdateScheduledRecharge,
  requestDeleteScheduledRecharge,
  requestNewScheduledRecharge,
} from "../api/scheduledRecharge";
import { getIdentifier } from "../selectors/session";
import { fetchHistoricScheduleRecharges } from "../api/recharge";

function* handleFetchScheduledRecharges() {
  yield put(doShowLoading());

  try {
    const identifier = yield select(getIdentifier);

    const { data } = yield call(fetchScheduledRecharges);

    data.sort(recharge => {
      const { recipient } = recharge;

      if (recipient === identifier) {
        return -1;
      }
      if (recipient > identifier) {
        return 1;
      }
      return 0;
    });

    yield putResolve(doSaveScheduledRecharges(data));

    const historyScheduleRecharges = yield call(fetchHistoricScheduleRecharges);

    yield putResolve(doSaveHistoricScheduledRecharges(historyScheduleRecharges.data));
  } catch (e) {
    yield put(doShowError("Erro ao carregar as recargas programadas.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestUpdateScheduledRecharge(action) {
  try {
    yield put(doShowLoading());
    const { scheduledRecharge } = action.payload;
    yield call(requestUpdateScheduledRecharge, scheduledRecharge);
    yield put(doSuccessUpdateScheduledRecharge());
    yield put(push("confirmacao"));
  } catch (e) {
    yield put(doShowError("Erro ao tentar editar recarga programada.", e));
    yield put(doFailureUpdateScheduledRecharge());
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestDeleteScheduledRecharge(action) {
  try {
    yield put(doShowLoading());
    const { externalId } = action.payload;
    yield call(requestDeleteScheduledRecharge, externalId);
    yield put(doFilterScheduledRecharges(externalId));
    yield put(doShowSnackbar("success", "Recarga programada cancelada com sucesso"));
    yield put(push("home"));
  } catch (e) {
    yield put(doShowError("Erro ao tentar excluir recarga programada.", e));
  } finally {
    yield put(doResetEditableScheduledRecharge());
    yield put(doHideLoading());
  }
}

function* handleRequestNewScheduledRecharge(action) {
  yield put(doShowLoading());

  try {
    yield call(requestNewScheduledRecharge, action.payload);
    yield put(doScheduledRechargeSuccess());
    yield put(push("confirmacao"));
  } catch (e) {
    yield put(doShowError("Erro ao efetuar a recarga programada.", e));
    yield put(doScheduledRechargeError());
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestResetEditableScheduledRecharge() {
  yield delay(3000);
  yield put(doResetEditableScheduledRecharge());
}

export {
  handleFetchScheduledRecharges,
  handleRequestUpdateScheduledRecharge,
  handleRequestDeleteScheduledRecharge,
  handleRequestNewScheduledRecharge,
  handleRequestResetEditableScheduledRecharge,
};
