import channels from "../enum/channels";

export const getCurrentChannel = location => {
  let channelPath = "recarga";

  // eslint-disable-next-line no-restricted-syntax
  for (const channel in channels) {
    if (location.pathname.split("/").includes(channel)) {
      channelPath = channel;
      break;
    }
  }

  const channelName = channels[channelPath];

  return [channelPath, channelName];
};

export const isChannelNoCredit = () => sessionStorage.getItem("channel") === channels["no-credit"];

export const isNoDataOrNoCreditChannel = (channel = null) =>
  [channels.clarouraone, channels["no-credit"]].includes(
    channel || sessionStorage.getItem("channel")
  );
