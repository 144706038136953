import { MODAL_SHOW, MODAL_HIDE, LOGOUT_REQUEST } from "../../constants/actionTypes";

const INITIAL_STATE = {
  isVisible: false,
  currentTarget: "",
};

const applyShowModal = action => ({
  isVisible: true,
  currentTarget: action.target,
});

const applyHideModal = () => ({
  isVisible: false,
  currentTarget: "",
});

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODAL_SHOW:
      return applyShowModal(action);
    case MODAL_HIDE:
      return applyHideModal();
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default modalReducer;
