import CustomerGoVisaStates from "../enum/CustomerGoVisaStates";

export const INITIAL_STATE = {
  current: CustomerGoVisaStates.IDLE,
  loading: false,
  error: "",
  context: {
    msisdn: null,
    cardToken: null,
    reloadId: null,
    bin: null,
    last: null,
  },
};
