const knownErrors = {
  JA0002: "Cliente deve aguardar para solicitar uma nova recarga.",
  JA0009: "Cliente atingiu o limite de tentativa de recarga do período.",
  JA0013: "O destinatário atingiu o limite de recebimento diário",
  JA0014: "Foi atingido o limite de gasto diário do cliente.",
  JA0017: "Destinatário atingiu o limite de tentativa de recarga do período.",
  LI0003: "Cartão não relacionado a este cliente.",
  LI0004: "Destinatário não relacionado a este cliente.",
  LI0008: "Nenhum produto encontrado.",
  LI0012: "Já existe uma recarga com os mesmos parâmetros.",
  LI0029: "Fonte de pagamento não relacionada ao cliente.",
  LI0032: "Token inválido.",
  LI1011: "Tipo de pagamento desconhecido ou não permitido.",
  LI1015: "Falha de integração com gateway de pagamento",
  SNARF0030: "Foi atingido o limite máximo de dependentes.",
  SNARF0035: "Foi atingido o limite máximo de cartões.",
  SNARF0036: "Foi atingido o número máximo de cartões associados a este cliente.",
  SNARF0038: "Entrou na blacklist.",
  SNARF0039: "Foi atingido o limite máximo de destinatários.",
  SNARF0040: "Conta entrou na blacklist.",
  SNARF0043: "Foi atingido o limite máximo de inserções de cartão.",
  SNARF0046: "Este destinatário já está associado a este cliente.",
  WKIT0027: "Cliente não encontrado.",
  WKIT0028: "Destinatário/cartão não relacionado a este cliente.",
  WKIT0033: "Falha no serviço de recorrência.",
  WKIT0038: "Somente clientes ativos podem criar recargas programadas.",
  WKIT0040: "Foi atingida a cota para destinatário.",
  MA0001: "Erro ao solicitar recarga, por favor tente novamente.",
  MA0030:
    "Erro ao solicitar recarga. Tente através do nosso portal: https://clarorecarga.claro.com.br/ ",
  MA0031:
    "Erro ao solicitar recarga. Tente através do nosso portal: https://clarorecarga.claro.com.br/ ",
};

const DEFAULT_ERROR_MESSAGE = "Sua recarga não foi efetivada, por favor tente novamente.";
const BANK_ERROR_MESSAGE = "Sua recarga não foi efetivada, por favor contacte seu banco.";
const M4U_ERROR_MESSAGE = "Sua recarga não foi efetivada, por favor tente novamente mais tarde.";
const CLARO_ERROR_MESSAGE =
  "Sua recarga não foi efetivada, por favor entre em contato com a Claro.";

const knownTvErrors = {
  LI0000: DEFAULT_ERROR_MESSAGE, // GenericERROR
  LI0002: DEFAULT_ERROR_MESSAGE, // payment_source_not_well_formed
  LI0003: DEFAULT_ERROR_MESSAGE, // card_not_related
  LI0012: M4U_ERROR_MESSAGE, // duplicated_reload
  LI0013: M4U_ERROR_MESSAGE, // invalid_payment_source_type
  LI0014: BANK_ERROR_MESSAGE, // cvv_not_well_formed
  LI1000: CLARO_ERROR_MESSAGE, // Erro benefício
  LI1007: BANK_ERROR_MESSAGE, // Invalid credit card
  LI1017: M4U_ERROR_MESSAGE, // Reload gateway integration error
  LI1019: M4U_ERROR_MESSAGE, // Reload authorization failure
  LI1020: M4U_ERROR_MESSAGE, // Reload confirmation failure
  LI1022: M4U_ERROR_MESSAGE, // Reload gateway integration error. Failure on reload canceling
  LI1023: CLARO_ERROR_MESSAGE, // Reload unauthorized
  LI1015: M4U_ERROR_MESSAGE, // Payment gateway integration error
  LI1009: BANK_ERROR_MESSAGE, // invalid CVV
  LI1026: M4U_ERROR_MESSAGE, // Customer already has similar transaction
  LI1004: BANK_ERROR_MESSAGE, // Blacklisted credit card
  LI1008: BANK_ERROR_MESSAGE, // Expired credit card
  LI1011: BANK_ERROR_MESSAGE, // Not yet unlocked credit card
  LI1027: BANK_ERROR_MESSAGE, // Credit card has insufficient funds
  LI1010: BANK_ERROR_MESSAGE, // Payment transaction denied
  LI1028: DEFAULT_ERROR_MESSAGE, // Payment authorization failed due to generic reason
  JA0002: M4U_ERROR_MESSAGE,
  JA0009: M4U_ERROR_MESSAGE,
  JA0013: M4U_ERROR_MESSAGE,
  JA0014: M4U_ERROR_MESSAGE,
  JA0017: M4U_ERROR_MESSAGE,
};

module.exports = {
  knownErrors,
  knownTvErrors,
};
