import "react-app-polyfill/ie9";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  applyPolyfills,
  defineCustomElements,
} from "@meios-pagamentos/eldorado-bridge-component/loader";

import { ThemeProvider } from "styled-components";
import App from "./components/App";
import history from "./history";
import store from "./store";

import { STYLES, GlobalFonts, GlobalStyle } from "./styles/_utilities";
import "./index.scss";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={STYLES}>
      <App history={history} />
      <GlobalFonts />
      <GlobalStyle />
    </ThemeProvider>
  </Provider>,
  document.getElementById("app-root")
);

applyPolyfills().then(() => {
  defineCustomElements();
});
