import {
  bonusRecurringPrice,
  bonusRecurringPriceMinhaClaro,
  bonusRecurringPriceDDDs,
} from "../enum/bonusRecurringPrice";
import { customers } from "../enum/customers";
import { dddValidate, getDDD, isControlChannel, isMinhaClaro } from "../lib/utils";
import segments from "../enum/segments";
import specificDDDs from "../enum/specificDDDs";

const MIN_RECHARGE_VALUE = 2500;
const MIN_RECHARGE_VALUE_APP = 2000;
const MIN_RECHARGE_VALUE_FIRST_SCHEDULE = 2500;
const getCustomerState = ({ customerState }) => customerState;

const getBonus = (price, channel, segment, identifier) => {
  const ddd = identifier ? getDDD(identifier) : "";

  if (segment === segments.controle) return "";
  if (isMinhaClaro(channel) && price >= MIN_RECHARGE_VALUE_APP) {
    return bonusRecurringPriceMinhaClaro[price];
  }
  if (dddValidate(ddd, specificDDDs) && price >= MIN_RECHARGE_VALUE_APP && !isMinhaClaro(channel)) {
    return bonusRecurringPriceDDDs[price];
  }
  if (price >= MIN_RECHARGE_VALUE) {
    return bonusRecurringPrice[price];
  }
  return "";
};

const haveBonus = (price, isSchedule, isFirstSchedule, channel, segment, identifier) => {
  const ddd = identifier ? getDDD(identifier) : "";

  if (isControlChannel(channel) || segment === segments.controle) {
    return false;
  }
  if (isSchedule && isFirstSchedule) {
    return price >= MIN_RECHARGE_VALUE_FIRST_SCHEDULE;
  }
  if (dddValidate(ddd, specificDDDs) && !isMinhaClaro(channel)) {
    return price >= MIN_RECHARGE_VALUE_APP;
  }
  if (isMinhaClaro(channel)) {
    return price >= MIN_RECHARGE_VALUE_APP;
  }
  return price >= MIN_RECHARGE_VALUE;
};

const getAvailableBonus = (
  price,
  customerState,
  rechargeState,
  scheduleRecharge,
  historicScheduleRecharge,
  channel,
  segment,
  identifier
) => {
  if (segment === segments.controle) return "";

  const isSchedule = rechargeState?.isScheduled || false;
  const isFirstSchedule =
    isSchedule && !scheduleRecharge.length && !historicScheduleRecharge.length;

  if (!haveBonus(price, isSchedule, isFirstSchedule, channel, segment, identifier)) return "";

  const isCustomerCreated = customerState?.status === customers.CREATED;
  const isNewSchedule = isSchedule && isFirstSchedule;

  if (isCustomerCreated && !isSchedule) {
    return bonusRecurringPrice.DEFAULT;
  }
  if (isNewSchedule) {
    return bonusRecurringPrice.DEFAULT;
  }

  return getBonus(price, channel, segment, identifier);
};

export { getCustomerState, getAvailableBonus, getBonus, haveBonus };
