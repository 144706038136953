import {
  EDITABLE_SCHEDULED_RECHARGE_SAVE,
  EDITABLE_SCHEDULED_RECHARGE_CHANGE,
  EDITABLE_SCHEDULED_RECHARGE_RESET,
  SCHEDULED_RECHARGES_FETCH,
  SCHEDULED_RECHARGES_SAVE,
  SCHEDULED_RECHARGES_FILTER,
  SCHEDULED_RECHARGES_SUCCESS,
  SCHEDULED_RECHARGES_ERROR,
  UPDATE_SCHEDULED_RECHARGE_REQUEST,
  UPDATE_SCHEDULED_RECHARGE_SUCCESS,
  UPDATE_SCHEDULED_RECHARGE_FAILURE,
  DELETE_SCHEDULED_RECHARGE_REQUEST,
  NEW_SCHEDULED_RECHARGE_REQUEST,
  EDITABLE_SCHEDULED_RECHARGE_STATUS_CHANGE,
  RESET_EDITABLE_SCHEDULED_RECHARGE_REQUEST,
  MANAGE_SCHEDULE_RECHARGE,
  HISTORIC_SCHEDULED_RECHARGES_SAVE,
} from "../constants/actionTypes";

const doScheduledRechargeSuccess = () => ({
  type: SCHEDULED_RECHARGES_SUCCESS,
});

const doScheduledRechargeError = () => ({
  type: SCHEDULED_RECHARGES_ERROR,
});

const doSaveEditableScheduledRecharge = scheduledRecharge => ({
  type: EDITABLE_SCHEDULED_RECHARGE_SAVE,
  payload: scheduledRecharge,
});

const doChangeEditableScheduledRecharge = (key, value) => ({
  type: EDITABLE_SCHEDULED_RECHARGE_CHANGE,
  payload: {
    key,
    value,
  },
});

const doResetEditableScheduledRecharge = () => ({
  type: EDITABLE_SCHEDULED_RECHARGE_RESET,
});

const doFetchScheduledRecharges = () => ({
  type: SCHEDULED_RECHARGES_FETCH,
});

const doSaveScheduledRecharges = scheduledRecharges => ({
  type: SCHEDULED_RECHARGES_SAVE,
  payload: scheduledRecharges,
});

const doSaveHistoricScheduledRecharges = recharges => ({
  type: HISTORIC_SCHEDULED_RECHARGES_SAVE,
  payload: recharges,
});

const doFilterScheduledRecharges = externalId => ({
  type: SCHEDULED_RECHARGES_FILTER,
  payload: {
    externalId,
  },
});

const doRequestUpdateScheduledRecharge = scheduledRecharge => ({
  type: UPDATE_SCHEDULED_RECHARGE_REQUEST,
  payload: {
    scheduledRecharge,
  },
});

const doSuccessUpdateScheduledRecharge = () => ({
  type: UPDATE_SCHEDULED_RECHARGE_SUCCESS,
});

const doFailureUpdateScheduledRecharge = () => ({
  type: UPDATE_SCHEDULED_RECHARGE_FAILURE,
});

const doRequestDeleteScheduledRecharge = externalId => ({
  type: DELETE_SCHEDULED_RECHARGE_REQUEST,
  payload: {
    externalId,
  },
});

const doRequestNewScheduledRecharge = payload => ({
  type: NEW_SCHEDULED_RECHARGE_REQUEST,
  payload,
});

const doChangeEditableScheduledRechargeStatus = status => ({
  type: EDITABLE_SCHEDULED_RECHARGE_STATUS_CHANGE,
  payload: {
    status,
  },
});

const doRequestResetEditableScheduledRecharge = () => ({
  type: RESET_EDITABLE_SCHEDULED_RECHARGE_REQUEST,
});

const doManageScheduleRecharge = payload => ({
  type: MANAGE_SCHEDULE_RECHARGE,
  payload,
});

export {
  doScheduledRechargeSuccess,
  doScheduledRechargeError,
  doSaveEditableScheduledRecharge,
  doChangeEditableScheduledRecharge,
  doResetEditableScheduledRecharge,
  doFetchScheduledRecharges,
  doSaveScheduledRecharges,
  doFilterScheduledRecharges,
  doRequestUpdateScheduledRecharge,
  doSuccessUpdateScheduledRecharge,
  doFailureUpdateScheduledRecharge,
  doRequestDeleteScheduledRecharge,
  doRequestNewScheduledRecharge,
  doChangeEditableScheduledRechargeStatus,
  doRequestResetEditableScheduledRecharge,
  doManageScheduleRecharge,
  doSaveHistoricScheduledRecharges,
};
