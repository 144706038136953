import { PROMOTION_FETCH, PROMOTION_ADD } from "../constants/actionTypes";

export const doFetchPromotions = () => ({
  type: PROMOTION_FETCH,
});

export const doSavePromotions = promotions => ({
  type: PROMOTION_ADD,
  payload: promotions,
});
