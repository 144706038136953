import {
  HIDE_OTP_MODAL,
  OTP_VALIDATE_SMS_TOKEN,
  RESET_STATE,
  RESET_STATE_OTP,
  SHOW_OTP_MODAL,
  UNVALIDATE_TOKEN,
  VALIDATE_TOKEN,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  showModal: false,
  validToken: false,
};

const applyShowOTPModal = state => ({
  ...state,
  showModal: true,
});

const applyHideOTPModal = state => ({
  ...state,
  showModal: false,
});

const applyValidToken = state => ({
  ...state,
  validToken: true,
});

const applyInvalidToken = state => ({
  ...state,
  validToken: false,
});

const otpModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_OTP_MODAL:
      return applyShowOTPModal(state);
    case HIDE_OTP_MODAL:
      return applyHideOTPModal(state);
    case VALIDATE_TOKEN:
      return applyValidToken(state);
    case UNVALIDATE_TOKEN:
      return applyInvalidToken(state);
    case OTP_VALIDATE_SMS_TOKEN:
      return { ...state };
    case RESET_STATE_OTP:
      return INITIAL_STATE;
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default otpModalReducer;
