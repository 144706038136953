import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";

// PAYPAL
import { PAYPAL_FAILURE_PATH, PAYPAL_SUCCESS_PATH } from "../constants/paths";

import Loading from "./modules/Loading";
import SuspenseLoading from "./modules/SuspenseLoading";
import PrivateRoute from "./modules/Routes/PrivateRoute";
import PublicRoute from "./modules/Routes/PublicRoute";
import AuthRoute from "./modules/Routes/AuthRoute";
import Wrapper from "./modules/Wrapper";
import WindowResize from "./modules/WindowResize";

import asyncComponent from "./hoc/AsyncComponent";
import { doInitializeGA } from "../actions/analytics";

const AsyncPlayground = asyncComponent(() => import("../playground/Playground"));
// LOGOUT
const AsyncLogout = asyncComponent(() => import("./pages/Logout"));

// FLEX WEBVIEW
const AsyncFlexWebViewHome = asyncComponent(() => import("./pages/FlexWebView/Home"));

// WEB
const AsyncNewHome = asyncComponent(() => import("./pages/Web/NewHome/NewHome"));
const AsyncPending = asyncComponent(() => import("./pages/Web/Pending"));
const AsyncFailure = asyncComponent(() => import("./pages/Web/Failure"));
const AsyncAddCard = asyncComponent(() => import("./pages/Web/AddCard"));
const AsyncAddCardDebitoCaixa = asyncComponent(() => import("./pages/Web/AddCardDebitoCaixa"));
const AsyncAddCvv = asyncComponent(() => import("./pages/Web/AddCvv"));
const AsyncSelectNumber = asyncComponent(() => import("./pages/Web/SelectNumber"));
const AsyncNotFound = asyncComponent(() => import("./pages/NotFound"));
const AsynEncrcyptedLogin = asyncComponent(() =>
  import("./pages/Login/EncryptedLogin/EncryptedLogin")
);
const AsyncLogin = asyncComponent(() => import("./pages/Login"));
const AsyncSelectCard = asyncComponent(() => import("./pages/Web/SelectCard"));
const AsyncHistory = asyncComponent(() => import("./pages/Web/History"));
const AsyncScheduleManagement = asyncComponent(() =>
  import("./pages/Web/ScheduleManagement/ScheduleManagement")
);
const AsyncTimeline = asyncComponent(() => import("./pages/Web/Timeline"));
const AsyncCustomerData = asyncComponent(() => import("./pages/Web/CustomerData/CustomerData.js"));
const AsyncCardsDebitoCaixa = asyncComponent(() => import("./pages/Web/CardsDebitoCaixa"));
const AsyncSelectPayPal = asyncComponent(() => import("./pages/Web/SelectPayPal"));
const AsyncPayPalAuthorized = asyncComponent(() => import("./pages/Web/PayPalAuthorized"));
const AsyncPayPalFailure = asyncComponent(() => import("./pages/Web/PayPalFailure"));
const AsyncPayPalAccounts = asyncComponent(() => import("./pages/Web/PayPalAccounts"));
const AsyncRegistrationDeleted = asyncComponent(() =>
  import("./pages/Web/RegistrationDeleted/RegistrationDeleted")
);
const AsyncSelectMercadoPago = lazy(() => import("./pages/Web/SelectMercadoPago"));
const AsyncSelectGooglePay = lazy(() => import("./pages/Web/SelectGooglePay"));
const AsyncSelectCardCaixa = lazy(() => import("./pages/Web/SelectCardCaixa"));
const AsyncNewConfirmationPix = lazy(() => import("./pages/Web/ConfirmationPix/ConfirmationPix"));
const AsyncAddNewCard = lazy(() => import("./pages/Web/CreateCard/CreateCard"));
const AsyncNewConfirmationPixDesktop = lazy(() =>
  import("./pages/Web/ConfirmationPix/ConfirmationPixDesktop")
);
const AsyncRechargeValues = asyncComponent(() => import("./pages/Web/RechargeValuesPage"));
const AsyncLandingPage = asyncComponent(() => import("./pages/Web/LandingPage"));

// MOBILE
const AsyncAddCardMobile = asyncComponent(() => import("./pages/Web/AddCard/mobile"));
const AsyncAddCardDebitoCaixaMobile = asyncComponent(() =>
  import("./pages/Web/AddCardDebitoCaixa/mobile")
);
const AsyncAddCvvMobile = asyncComponent(() => import("./pages/Web/AddCvv/mobile"));
const AsyncPendingMobile = asyncComponent(() => import("./pages/Web/Pending/mobile"));
const AsyncFailureMobile = asyncComponent(() => import("./pages/Web/Failure/mobile"));
const AsyncHistoryMobile = asyncComponent(() => import("./pages/Web/History/mobile"));
const AsyncSelectNumberMobile = asyncComponent(() => import("./pages/Web/SelectNumber/mobile"));
const AsyncSelectCardMobile = asyncComponent(() => import("./pages/Web/SelectCard/mobile"));
const AsyncNewSelectInstallmentsMobile = asyncComponent(() =>
  import("./pages/Web/NewSelectInstallments/NewSelectInstallments")
);
const AsyncCardsDebitoCaixaMobile = asyncComponent(() =>
  import("./pages/Web/CardsDebitoCaixa/mobile")
);
const AsyncSelectPayPalMobile = asyncComponent(() => import("./pages/Web/SelectPayPal/mobile"));
const AsyncSelectMercadoPagoMobile = asyncComponent(() =>
  import("./pages/Web/SelectMercadoPago/mobile")
);
const AsyncSelectGooglePayMobile = lazy(() => import("./pages/Web/SelectGooglePay/mobile"));
const AsyncNewSelectDebitoCaixaMobile = lazy(() =>
  import("./pages/Web/SelectDebitoCaixa/NewSelectDebitoCaixa")
);
const AsyncSelectCardCaixaMobile = lazy(() => import("./pages/Web/SelectCardCaixa/mobile"));
const AsyncPayPalAccountsMobile = asyncComponent(() => import("./pages/Web/PayPalAccounts/mobile"));
const AsyncBalanceMobile = asyncComponent(() => import("./pages/Web/Balance/index"));
const AsyncPaymentWithCard = asyncComponent(() =>
  import("./pages/Web/PaymentWithCard/PaymentWithCard")
);
const AsyncPaymentWithCardDesktop = asyncComponent(() =>
  import("./pages/Web/PaymentWithCard/PaymentWithCardDesktop")
);
const AsyncNewConfirmation = asyncComponent(() =>
  import("./pages/Web/Confirmation/NewConfirmation.js")
);
const AsyncEncryptedRechargePix = asyncComponent(() =>
  import("./pages/Web/EncryptedPixRecharge/EncryptedPixRecharge")
);
const AsyncErrorPayment = asyncComponent(() => import("./pages/Web/ErrorPayment/ErrorPayment"));
const AsyncSuccessPayment = asyncComponent(() =>
  import("./pages/Web/SuccessPayment/SuccessPayment")
);
const EncryptedConfirmRepeatRecharge = asyncComponent(() =>
  import("./pages/Web/EncryptedConfirmRepeatRecharge/EncryptedConfirmRepeatRecharge")
);
const reload = () => window.location.reload();

const App = ({ history, onInitializeGA }) => {
  onInitializeGA();

  return (
    <Wrapper>
      <Loading />
      <Suspense fallback={<SuspenseLoading />}>
        <ConnectedRouter history={history}>
          <Switch>
            {/* ================================= Playground ROUTE ================================== */}
            {process.env.NODE_ENV === "development" && (
              <PublicRoute
                path="/playground"
                exact
                component={AsyncPlayground}
                mobile={AsyncPlayground}
              />
            )}
            {/* ================================= PUBLIC ROUTES ================================== */}
            <Route
              exact
              path="/:channel(tv)/*"
              // eslint-disable-next-line no-return-assign
              render={() => {
                const { msisdn, identifier } = qs.parse(window.location.search, {
                  ignoreQueryPrefix: true,
                });
                window.location = `https://portal.claro-tv.m4u.com.br?identifier=${msisdn ??
                  identifier}`;
              }}
            />
            <Redirect
              path="/"
              exact
              to={`/${sessionStorage.getItem("channelPath") || "recarga"}`}
            />
            <AuthRoute path="/:channel" exact component={AsyncLogin} mobile={AsyncLogin} />
            <PublicRoute
              path="/:channel/logout"
              exact
              component={AsyncLogout}
              mobile={AsyncLogout}
            />
            <PublicRoute
              path="/:channel/pix"
              exact
              component={AsyncEncryptedRechargePix}
              mobile={AsyncEncryptedRechargePix}
            />
            <PublicRoute
              path="/flex-webview/home"
              exact
              component={AsyncFlexWebViewHome}
              mobile={AsyncFlexWebViewHome}
            />
            <PublicRoute
              path="/:channel/landing"
              exact
              component={AsyncLandingPage}
              mobile={AsyncLandingPage}
            />
            {/* ================================== AUTH ROUTES =================================== */}
            <AuthRoute
              path="/:channel/*login"
              exact
              component={AsynEncrcyptedLogin}
              mobile={AsynEncrcyptedLogin}
            />
            {/* ================================= PRIVATE ROUTES ================================= */}
            <PrivateRoute
              path="/:channel/home"
              exact
              component={AsyncNewHome}
              mobile={AsyncNewHome}
            />
            <PrivateRoute
              path="/:channel/confirmacao"
              exact
              component={AsyncNewConfirmation}
              mobile={AsyncNewConfirmation}
            />
            <PrivateRoute
              path="/:channel/pendencia"
              exact
              component={AsyncPending}
              mobile={AsyncPendingMobile}
            />
            <PrivateRoute
              path="/:channel/falha"
              exact
              component={AsyncFailure}
              mobile={AsyncFailureMobile}
            />
            <PrivateRoute
              path="/:channel/valores-mobile"
              exact
              component={AsyncRechargeValues}
              mobile={AsyncRechargeValues}
            />
            <PrivateRoute
              path="/:channel/novo-credito"
              exact
              component={AsyncAddCard}
              mobile={AsyncAddCardMobile}
            />
            <PrivateRoute
              path="/:channel/novo-debitocef"
              exact
              component={AsyncAddCardDebitoCaixa}
              mobile={AsyncAddCardDebitoCaixaMobile}
            />
            <PrivateRoute
              path="/:channel/pagamento-cvv"
              exact
              component={AsyncAddCvv}
              mobile={AsyncAddCvvMobile}
            />
            <PrivateRoute
              path="/:channel/numero"
              exact
              component={AsyncSelectNumber}
              mobile={AsyncSelectNumberMobile}
            />
            <PrivateRoute
              path="/:channel/pagamento-credito"
              exact
              component={AsyncSelectCard}
              mobile={AsyncSelectCardMobile}
            />

            <PrivateRoute
              path="/:channel/pagamento-parcelamento"
              exact
              component={AsyncNewSelectInstallmentsMobile}
              mobile={AsyncNewSelectInstallmentsMobile}
            />
            <PrivateRoute
              path="/:channel/meus-dados"
              exact
              component={AsyncCustomerData}
              mobile={AsyncCustomerData}
            />
            <PrivateRoute
              path="/:channel/debitocef"
              exact
              component={AsyncCardsDebitoCaixa}
              mobile={AsyncCardsDebitoCaixaMobile}
            />
            <PrivateRoute
              path="/:channel/minhas-recargas"
              exact
              component={AsyncHistory}
              mobile={AsyncHistoryMobile}
            />
            <PrivateRoute
              path="/:channel/gerenciar-programadas"
              exact
              component={AsyncScheduleManagement}
              mobile={AsyncScheduleManagement}
            />
            <PrivateRoute
              path="/:channel/historico"
              exact
              component={AsyncTimeline}
              mobile={AsyncTimeline}
            />
            <PrivateRoute
              path="/:channel/pagamento-paypal"
              exact
              component={AsyncSelectPayPal}
              mobile={AsyncSelectPayPalMobile}
            />
            <PrivateRoute
              path="/:channel/exclusao-confirmacao"
              exact
              component={AsyncRegistrationDeleted}
              mobile={AsyncRegistrationDeleted}
            />
            <PrivateRoute
              path={PAYPAL_SUCCESS_PATH}
              exact
              component={AsyncPayPalAuthorized}
              mobile={AsyncPayPalAuthorized}
            />
            <PrivateRoute
              path={PAYPAL_FAILURE_PATH}
              exact
              component={AsyncPayPalFailure}
              mobile={AsyncPayPalFailure}
            />
            <PrivateRoute
              path="/:channel/paypal"
              exact
              component={AsyncPayPalAccounts}
              mobile={AsyncPayPalAccountsMobile}
            />
            <PrivateRoute
              path="/:channel/pagamento-mercadopago"
              exact
              component={AsyncSelectMercadoPago}
              mobile={AsyncSelectMercadoPagoMobile}
            />
            <PrivateRoute
              path="/:channel/pagamento-googlepay"
              exact
              component={AsyncSelectGooglePay}
              mobile={AsyncSelectGooglePayMobile}
            />
            <PrivateRoute
              path="/:channel/pagamento-debitocef-codigo"
              exact
              component={AsyncNewSelectDebitoCaixaMobile}
              mobile={AsyncNewSelectDebitoCaixaMobile}
            />

            <PrivateRoute
              path="/:channel/pagamento-debitocef"
              exact
              component={AsyncSelectCardCaixa}
              mobile={AsyncSelectCardCaixaMobile}
            />
            <PrivateRoute
              path="/:channel/confirmacao-pix"
              exact
              component={AsyncNewConfirmationPixDesktop}
              mobile={AsyncNewConfirmationPix}
            />
            <PrivateRoute
              path="/:channel/saldo"
              exact
              component={AsyncBalanceMobile}
              mobile={AsyncBalanceMobile}
            />
            <PrivateRoute
              path="/:channel/pagamento-cartao"
              exact
              component={AsyncPaymentWithCardDesktop}
              mobile={AsyncPaymentWithCard}
            />
            <PrivateRoute
              path="/:channel/pagamento-erro"
              exact
              component={AsyncErrorPayment}
              mobile={AsyncErrorPayment}
            />
            <PrivateRoute
              path="/:channel/pagamento-sucesso"
              exact
              component={AsyncSuccessPayment}
              mobile={AsyncSuccessPayment}
            />

            <PrivateRoute
              path="/:channel/confirm-repeat"
              exact
              component={EncryptedConfirmRepeatRecharge}
              mobile={EncryptedConfirmRepeatRecharge}
            />

            <PrivateRoute
              path="/:channel/criar-cartao"
              exact
              component={AsyncAddNewCard}
              mobile={AsyncAddNewCard}
            />
            <Route component={AsyncNotFound} />
            <Route path="/analytics.txt" onEnter={reload} />
          </Switch>
        </ConnectedRouter>
      </Suspense>
      <WindowResize />
    </Wrapper>
  );
};

App.propTypes = {
  history: PropTypes.object.isRequired,
  onInitializeGA: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onInitializeGA: doInitializeGA,
};

export default connect(
  null,
  mapDispatchToProps
)(App);
