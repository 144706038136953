import {
  NEW_TV_RECHARGE_CHANGE,
  NEW_TV_RECHARGE_ERROR,
  LOGOUT_REQUEST,
  RESET_STATE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  newTvRecharge: {
    target: "",
    product: "",
    tvPackage: "",
    period: "",
    extra: "",
  },
  tvRecharges: [],
  lastRechargeResult: null,
};

const applyChangeNewTvRecharge = (state, action) => {
  const { key, value } = action.payload;
  const { newTvRecharge } = state;
  const updatedNewTvRecharge = { ...newTvRecharge, [key]: value };
  return { ...state, newTvRecharge: updatedNewTvRecharge, lastRechargeResult: null };
};

const tvRechargeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEW_TV_RECHARGE_CHANGE:
      return applyChangeNewTvRecharge(state, action);
    case NEW_TV_RECHARGE_ERROR:
      return { ...state, lastRechargeResult: action.payload };
    case LOGOUT_REQUEST:
      return INITIAL_STATE;
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default tvRechargeReducer;
