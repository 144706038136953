import {
  PAYMENT_METHODS_ADD,
  PAYMENT_METHODS_STATUS_RESET,
  REGISTER_CREDIT_CARD_SUCCESS,
  REGISTER_CREDIT_CARD_FAILURE,
  PAYMENT_METHODS_REMOVE_SUCCESS,
  LOGIN_SUCCESS,
  RESET_STATE,
} from "../constants/actionTypes";
import paymentMethodsEnum from "../enum/paymentMethods";

const INITIAL_STATE = {
  paymentMethods: [],
  loginCardToken: "",
};

const applyAddLoginCardToken = (state, action) => {
  const { loginCardToken } = action.payload;
  return { ...state, loginCardToken, paymentMethods: [] };
};

const applyAddPaymentMethods = (state, action) => ({
  ...state,
  paymentMethods: action.payload,
  status: "",
});

const applyResetPaymentMethodsStatus = state => ({
  ...state,
  status: "",
});

const applySuccessRegisterCreditCard = (state, action) => {
  const { creditCard } = action.payload;
  const { paymentMethods } = state;

  const otherOptions = paymentMethods.filter(elem => elem.type !== paymentMethodsEnum.CREDITO);
  const creditOptions = paymentMethods.find(elem => elem.type === paymentMethodsEnum.CREDITO);
  const updatedCreditOptions = {
    ...creditOptions,
    elements: [...creditOptions.elements, creditCard],
  };

  return { ...state, paymentMethods: [updatedCreditOptions, ...otherOptions], status: "success" };
};

const applySuccessRemovePaymentMethod = (state, action) => {
  const { type, token } = action.payload;
  const { paymentMethods } = state;

  const otherTypes = paymentMethods.filter(elem => elem.type !== type);
  const typeOptions = paymentMethods.find(elem => elem.type === type);
  const updatedTypeOptions = {
    ...typeOptions,
    elements: typeOptions.elements.filter(elem => elem.token !== token),
  };

  return { ...state, paymentMethods: [updatedTypeOptions, ...otherTypes] };
};

const applyFailureRegisterCreditCard = state => ({
  ...state,
  status: "failure",
});

const paymentMethodReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_METHODS_ADD:
      return applyAddPaymentMethods(state, action);
    case PAYMENT_METHODS_STATUS_RESET:
      return applyResetPaymentMethodsStatus(state);
    case REGISTER_CREDIT_CARD_SUCCESS:
      return applySuccessRegisterCreditCard(state, action);
    case REGISTER_CREDIT_CARD_FAILURE:
      return applyFailureRegisterCreditCard(state);
    case PAYMENT_METHODS_REMOVE_SUCCESS:
      return applySuccessRemovePaymentMethod(state, action);
    case LOGIN_SUCCESS:
      return applyAddLoginCardToken(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default paymentMethodReducer;
