import { call, delay, put } from "redux-saga/effects";

import { requestResendToken, requestRevalidate } from "../api/revalidate";

import {
  doHideOTPModal,
  doShowOTPModal,
  doUnvalidateSMSToken,
  doValidateSMSToken,
} from "../actions/otpModal";
import { doHideLoading, doShowLoading, doShowError, doHideSnackbar } from "../actions/ui";

function* handleRevalidateSmsToken(action) {
  yield put(doShowLoading());
  const { payload } = action;

  try {
    yield call(requestRevalidate, payload);

    yield put(doHideSnackbar());

    yield put(doHideOTPModal());

    yield put(doValidateSMSToken());
  } catch (e) {
    yield put(doUnvalidateSMSToken());
    yield put(doShowOTPModal());
    yield put(doHideLoading());

    if (e.response.status === 400) {
      yield put(doShowError("Código inválido, tente novamente.", e));
    } else {
      yield put(doShowError("Erro durante o processo de revalidação do código.", e));
    }
  }
}

function* handleResendSmsToken(action) {
  const { payload } = action;

  try {
    yield put(doShowLoading());

    yield call(requestResendToken, payload);
  } catch (e) {
    yield put(doShowError("Erro ao reenviar o código de validação.", e));
  } finally {
    yield delay(250);
    yield put(doHideLoading());
  }
}

export { handleRevalidateSmsToken, handleResendSmsToken };
