import { RESET_CUSTOMER, SAVE_CUSTOMER, DELETE_CUSTOMER } from "../constants/actionTypes";

const doSaveCustomer = payload => ({
  type: SAVE_CUSTOMER,
  payload,
});

const doResetCustomer = () => ({ type: RESET_CUSTOMER });

const doDeleteCustomer = identifier => ({ type: DELETE_CUSTOMER, identifier });

export { doSaveCustomer, doResetCustomer, doDeleteCustomer };
