import { TV_PACKAGES_FETCH, TV_PACKAGES_ADD } from "../constants/actionTypes";

const doFetchTvPackages = () => ({
  type: TV_PACKAGES_FETCH,
});

const doSaveTvPackages = tvPackages => ({
  type: TV_PACKAGES_ADD,
  payload: tvPackages,
});

export { doFetchTvPackages, doSaveTvPackages };
