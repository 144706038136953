import {
  EXPRESS_RECHARGE_SAVE,
  EXPRESS_RECHARGE_CHANGE,
  EXPRESS_RECHARGE_STATUS_CHANGE,
  RESET_STATE,
} from "../constants/actionTypes";
import expressRechargeStatuses from "../enum/expressRechargeStatuses";

const INITIAL_STATE = {
  expressRecharge: {},
  status: "",
};

const applySaveExpressRecharge = (state, action) => ({
  ...state,
  expressRecharge: action.payload,
  status: expressRechargeStatuses.SHOW,
});

const applyChangeExpressRecharge = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    expressRecharge: { ...state.expressRecharge, [key]: value },
  };
};

const applyChangeExpressRechargeStatus = (state, action) => ({
  ...state,
  status: action.payload.status,
});

const rechargeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXPRESS_RECHARGE_SAVE:
      return applySaveExpressRecharge(state, action);
    case EXPRESS_RECHARGE_CHANGE:
      return applyChangeExpressRecharge(state, action);
    case EXPRESS_RECHARGE_STATUS_CHANGE:
      return applyChangeExpressRechargeStatus(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default rechargeReducer;
