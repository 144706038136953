import { call, put, select } from "redux-saga/effects";

import { doSaveTvPackages } from "../actions/tvPackage";
import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import { fetchTvPackages } from "../api/tvPackage";
import { getTvPackages } from "../selectors/tvPackage";
import productTVValues from "../staticFiles/productsTVV2.json";

function* handleFetchTvPackages2() {
  yield put(doShowLoading());

  try {
    const data = productTVValues;

    const tvPackages = {};

    data.forEach(tvPackage => {
      if (!tvPackages[tvPackage.name]) tvPackages[tvPackage.name] = {};

      tvPackages[tvPackage.name][tvPackage.description] = {
        id: tvPackage.id,
        price: tvPackage.price.lower_amount,
        components: {},
      };

      if (tvPackage.components)
        tvPackage.components.forEach(component => {
          tvPackages[tvPackage.name][tvPackage.description].components[component.description] = {
            name: component.description,
            price: tvPackage.price.lower_amount + component.amount,
          };
        });
    });

    yield put(doSaveTvPackages(tvPackages));
  } catch (e) {
    yield put(doShowError("Erro ao carregar os pacotes de TV.", e));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleFetchTvPackages() {
  yield put(doShowLoading());

  try {
    const tvPackagesSelect = yield select(getTvPackages);
    if (tvPackagesSelect.constructor === Array && tvPackagesSelect.length === 0) {
      const { data } = yield call(fetchTvPackages);
      const newData = data ? data.slice() : [];

      // sort packages by number of channels
      newData.sort(
        (a, b) =>
          parseInt(a.custom_attributes.number_of_channels, 10) -
          parseInt(b.custom_attributes.number_of_channels, 10)
      );

      const categories = new Set();

      newData.forEach(tvPackage => {
        categories.add(`${tvPackage.category} - ${tvPackage.custom_attributes.number_of_channels}`);
      });

      const tvPackages = [];

      categories.forEach(value => {
        const categorySplit = value.split(" - ");
        const category = categorySplit[0];
        const channelsCount = categorySplit[1];
        const plans = [];

        newData.forEach(tvPackage => {
          if (tvPackage.category === category) {
            plans.push({
              id: tvPackage.id,
              price: tvPackage.value,
              validity: tvPackage.custom_attributes.validity,
            });
          }
        });

        // sort plans in descending order of price
        plans.sort((a, b) => b.price - a.price);

        tvPackages.push({ category, channelsCount, plans });
      });

      yield put(doSaveTvPackages(tvPackages));
    }
  } catch (e) {
    yield put(doShowError("Erro ao carregar os pacotes de TV.", e));
  } finally {
    yield put(doHideLoading());
  }
}

export { handleFetchTvPackages, handleFetchTvPackages2 };
