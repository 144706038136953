import { RESET_STATE } from "../constants/actionTypes";

const INITIAL_STATE = {
  cards: [],
};

const cardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default cardReducer;
