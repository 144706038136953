import { combineReducers } from "redux";

import loadingReducer from "./loading";
import modalReducer from "./modal";
import snackbarReducer from "./snackbar";
import menuReducer from "./menu";
import drawerReducer from "./drawer";
import mediaReducer from "./media";
import targetBoxReducer from "./targetBox";

const uiReducer = combineReducers({
  loading: loadingReducer,
  modal: modalReducer,
  snackbar: snackbarReducer,
  menu: menuReducer,
  drawer: drawerReducer,
  media: mediaReducer,
  targetBox: targetBoxReducer,
});

export default uiReducer;
