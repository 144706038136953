import storage from "local-storage-fallback";
import { privateClient, publicClient } from "./client";

const requestNewRecharge = (data, fingerprint = "") =>
  privateClient.post(
    `/customers/${storage.getItem("identifier")}/recharges`,
    JSON.stringify(data),
    { headers: { "device-id": fingerprint } }
  );

const fetchRecharges = () =>
  privateClient.get(`/customers/${storage.getItem("identifier")}/recharges`);

const fetchHistoricScheduleRecharges = () =>
  privateClient.get(`/customers/${storage.getItem("identifier")}/recharges`, {
    headers: { reloadType: "recurring" },
  });

const requestNewPixEncryptedRecharge = data =>
  publicClient.post(`/recharges/encrypted`, JSON.stringify({ payload: data }));

export {
  requestNewRecharge,
  fetchRecharges,
  fetchHistoricScheduleRecharges,
  requestNewPixEncryptedRecharge,
};
