import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import rechargeValueReducer from "./rechargeValue";
import tvPackageReducer from "./tvPackage";
import tvRechargeReducer from "./tvRecharge";
import brandReducer from "./brand";
import cardReducer from "./card";
import rechargeReducer from "./recharge";
import expressRechargeReducer from "./expressRecharge";
import recipientReducer from "./recipient";
import sessionReducer from "./session";
import paymentMethodReducer from "./paymentMethod";
import uiReducer from "./ui";
import scheduledRechargeReducer from "./scheduledRecharge";
import homeReducer from "./home";
import fraudAnalysisReducer from "./fraudAnalysis";
import promotionReducer from "./promotion";
import featureReducer from "./feature";
import customerReducer from "./customer";
import goVisaReducer from "./goVisa";
import otpModalReducer from "./otpModal";
import rechargeResumeReducer from "./rechargeResume";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    brandState: brandReducer,
    cardState: cardReducer,
    recipientState: recipientReducer,
    rechargeValueState: rechargeValueReducer,
    rechargeState: rechargeReducer,
    expressRechargeState: expressRechargeReducer,
    sessionState: sessionReducer,
    customerState: customerReducer,
    tvPackageState: tvPackageReducer,
    tvRechargeState: tvRechargeReducer,
    paymentMethodState: paymentMethodReducer,
    scheduledRechargeState: scheduledRechargeReducer,
    uiState: uiReducer,
    homeState: homeReducer,
    fraudAnalysisState: fraudAnalysisReducer,
    promotionState: promotionReducer,
    featureState: featureReducer,
    goVisaState: goVisaReducer,
    otpModalState: otpModalReducer,
    rechargeResumeState: rechargeResumeReducer,
  });

export default rootReducer;
