import { privateClient } from "./client";

const check = async cardToken => {
  const response = await privateClient.get(`govisa/${cardToken}/activated`);
  return response.data;
};

const updateSubscription = async (cpf, { msisdn, cardToken, reloadId, bin, last }) => {
  return privateClient.put(`/govisa/${cardToken}`, {
    cpf,
    msisdn,
    reload_id: reloadId,
    bin,
    last,
  });
};

export { check, updateSubscription };
