import { call, put, delay } from "redux-saga/effects";

import { doSaveExpressRecharge, doChangeExpressRechargeStatus } from "../actions/expressRecharge";
import { doShowLoading, doHideLoading, doShowError } from "../actions/ui";
import { requestNewScheduledRecharge } from "../api/scheduledRecharge";
import expressRechargeStatuses from "../enum/expressRechargeStatuses";

function* handleRequestConfirmExpressRecharge(action) {
  try {
    yield put(doShowLoading());
    const { expressRecharge } = action.payload;
    yield call(requestNewScheduledRecharge, expressRecharge);
    yield put(doChangeExpressRechargeStatus(expressRechargeStatuses.SUCCESS));
  } catch (e) {
    yield put(doShowError("Erro ao efetuar a recarga expressa.", e));
    yield put(doChangeExpressRechargeStatus(expressRechargeStatuses.FAILURE));
  } finally {
    yield put(doHideLoading());
  }
}

function* handleRequestResetExpressRecharge(action) {
  const { expressRecharge } = action.payload;
  yield delay(3000);
  yield put(doSaveExpressRecharge(expressRecharge));
}

export { handleRequestConfirmExpressRecharge, handleRequestResetExpressRecharge };
