import {
  NEW_TV_RECHARGE_CHANGE,
  TV_PAYMENT_REQUEST,
  NEW_TV_RECHARGE_SUCCESS,
  NEW_TV_RECHARGE_ERROR,
} from "../constants/actionTypes";

const doChangeNewTvRecharge = (key, value) => ({
  type: NEW_TV_RECHARGE_CHANGE,
  payload: {
    key,
    value,
  },
});

const doRequestTvPayment = (newTvRecharge, pan, month, year, cvv) => ({
  type: TV_PAYMENT_REQUEST,
  payload: {
    newTvRecharge,
    pan,
    month,
    year,
    cvv,
  },
});

const doNewTvRechargeSuccess = () => ({
  type: NEW_TV_RECHARGE_SUCCESS,
});

const doNewTvRechargeError = error => ({
  type: NEW_TV_RECHARGE_ERROR,
  payload: error,
});

export { doChangeNewTvRecharge, doRequestTvPayment, doNewTvRechargeSuccess, doNewTvRechargeError };
