import {
  MENU_SELECT,
  LOADING_SHOW,
  LOADING_HIDE,
  MODAL_SHOW,
  MODAL_HIDE,
  SNACKBAR_SHOW,
  SNACKBAR_HIDE,
  LOGOUT_REQUEST,
  OPEN_DRAWER,
  CLOSE_DRAWER,
  CHANGE_MEDIA,
  TARGET_BOX_SELECT,
} from "../constants/actionTypes";

import { knownErrors } from "../enum/knownErrors";
import envs from "../enum/envs";

const doShowLoading = () => ({
  type: LOADING_SHOW,
});

const doHideLoading = () => ({
  type: LOADING_HIDE,
});

const doSelectMenu = path => ({
  type: MENU_SELECT,
  path,
});

const doShowModal = target => ({
  type: MODAL_SHOW,
  target,
});

const doHideModal = () => ({
  type: MODAL_HIDE,
});

const doShowSnackbar = (type, text) => ({
  type: SNACKBAR_SHOW,
  payload: {
    type,
    text,
  },
});

const doShowError = (defaultMessage, e) => {
  let statusCode = 500;
  let eMessage = defaultMessage || "Ocorreu um erro desconhecido.";

  if (e && e.response && e.response.status) {
    statusCode = e.response.status;

    if ([422, 500].includes(statusCode) && e.response.data) {
      let errors;

      if (e.response.data.data && e.response.data.data.errors) {
        // eslint-disable-next-line prefer-destructuring
        errors = e.response.data.data.errors;
      } else if (e.response.data.errors) {
        // Specific for scheduled recharges
        // eslint-disable-next-line prefer-destructuring
        errors = e.response.data.errors;
      }

      if (errors && errors[0] && errors[0].code) {
        if (knownErrors[errors[0].code]) {
          eMessage = `${knownErrors[errors[0].code]}`;
        }

        if (process.env.REACT_APP_NODE_ENV !== envs.production) {
          console.error(`(${statusCode}) - [${errors[0].code}] - ${errors[0].detail}`);
        }
      }
    }
  }

  if (statusCode === 401) {
    return { type: LOGOUT_REQUEST };
  }

  return {
    type: SNACKBAR_SHOW,
    payload: {
      type: "error",
      text: eMessage,
    },
  };
};

const doHideSnackbar = () => ({
  type: SNACKBAR_HIDE,
});

const doOpenDrawer = channelPath => ({
  type: OPEN_DRAWER,
  channelPath,
});

const doCloseDrawer = () => ({
  type: CLOSE_DRAWER,
});

const doChangeMedia = media => ({
  type: CHANGE_MEDIA,
  media,
});

const doSelectTargetBox = targetBox => ({
  type: TARGET_BOX_SELECT,
  targetBox,
});

export {
  doShowLoading,
  doHideLoading,
  doShowModal,
  doHideModal,
  doShowSnackbar,
  doHideSnackbar,
  doShowError,
  doSelectMenu,
  doOpenDrawer,
  doCloseDrawer,
  doChangeMedia,
  doSelectTargetBox,
};
