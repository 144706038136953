import {
  EXPRESS_RECHARGE_SAVE,
  EXPRESS_RECHARGE_CHANGE,
  CONFIRM_EXPRESS_RECHARGE_REQUEST,
  EXPRESS_RECHARGE_STATUS_CHANGE,
  RESET_EXPRESS_RECHARGE_REQUEST,
} from "../constants/actionTypes";

const doSaveExpressRecharge = expressRecharge => ({
  type: EXPRESS_RECHARGE_SAVE,
  payload: expressRecharge,
});

const doChangeExpressRecharge = (key, value) => ({
  type: EXPRESS_RECHARGE_CHANGE,
  payload: {
    key,
    value,
  },
});

const doRequestConfirmExpressRecharge = expressRecharge => ({
  type: CONFIRM_EXPRESS_RECHARGE_REQUEST,
  payload: {
    expressRecharge,
  },
});

const doChangeExpressRechargeStatus = status => ({
  type: EXPRESS_RECHARGE_STATUS_CHANGE,
  payload: {
    status,
  },
});

const doRequestResetExpressRecharge = expressRecharge => ({
  type: RESET_EXPRESS_RECHARGE_REQUEST,
  payload: {
    expressRecharge,
  },
});

export {
  doSaveExpressRecharge,
  doChangeExpressRecharge,
  doRequestConfirmExpressRecharge,
  doChangeExpressRechargeStatus,
  doRequestResetExpressRecharge,
};
