import { put, select, call } from "redux-saga/effects";
import {
  doUpdateGoVisaStatus,
  doUpdateGoVisaCurrentState,
  doShowGoVisaError,
} from "../actions/goVisa";
import CustomerGoVisaStates from "../enum/CustomerGoVisaStates";
import { getGoVisaState } from "../selectors/goVisa";
import { check, updateSubscription } from "../api/goVisa";

function* isGoVisaDisabled() {
  const goVisaToggle = yield select(({ featureState }) => {
    return featureState.go_visa;
  });
  return !goVisaToggle;
}

function* setCustomerToPreSelected(customerInfo, reloadId, cardToken, paymentInfo) {
  yield put(
    doUpdateGoVisaStatus(
      CustomerGoVisaStates.PRE_SELECTED,
      customerInfo.msisdn,
      reloadId,
      cardToken,
      paymentInfo
    )
  );
}

function* handleCallbackRecharge(action) {
  if (yield call(isGoVisaDisabled)) return;
  const { customerInfo, paymentInfo, paymentMethod, reloadInfo } = action.payload;
  if (
    paymentMethod.type === "credit" &&
    paymentInfo.brandName === "VISA" &&
    reloadInfo.value >= 2000
  ) {
    try {
      const { isGoVisa } = yield call(check, paymentMethod.data.token);
      if (isGoVisa) {
        yield put(doUpdateGoVisaCurrentState(CustomerGoVisaStates.SUBSCRIBED));
      } else {
        yield call(
          setCustomerToPreSelected,
          customerInfo,
          reloadInfo.id,
          paymentMethod.data.token,
          paymentInfo
        );
      }
    } catch (e) {
      yield call(
        setCustomerToPreSelected,
        customerInfo,
        reloadInfo.id,
        paymentMethod.data.token,
        paymentInfo
      );
    }
  }
}

function* handleUpdateGoVisaSubscription(action) {
  try {
    const { cpf } = action.payload;
    const goVisaState = yield select(getGoVisaState);
    yield put(doUpdateGoVisaCurrentState(CustomerGoVisaStates.APROVED));
    yield call(updateSubscription, cpf, goVisaState.context);
  } catch (error) {
    yield put(doShowGoVisaError("Erro ao cadastrar CPF. Por favor, tente novamente mais tarde."));
  }
}

export { handleCallbackRecharge, handleUpdateGoVisaSubscription };
