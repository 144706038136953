import { delay, put, select } from "redux-saga/effects";
import { hotjar } from "react-hotjar";

import { doHideSnackbar, doHideModal, doHideLoading, doCloseDrawer } from "../actions/ui";
import history from "../history";
import { getCurrentChannel } from "../lib/channelValidator";
import channels from "../enum/channels";
import { getSnackbarState } from "../selectors/ui";
import rechargeErrors from "../errors/rechargeErrors";

function* handleLocationChange() {
  const [channelPath, channelName] = getCurrentChannel(history.location);

  sessionStorage.setItem("channel", channelName);
  sessionStorage.setItem("channelPath", channelPath);

  if (channelName !== channels["no-credit"]) {
    hotjar.initialize(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }

  const { type, text } = yield select(state => getSnackbarState(state));

  yield put(doHideLoading());
  yield put(doHideModal());
  yield put(doCloseDrawer());
  if (type !== "error" || text === rechargeErrors[400].handlingMessage) {
    yield delay(5000);
  }
  yield put(doHideSnackbar());
}

export { handleLocationChange };
