export const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const MENU_SELECT = "MENU_SELECT";
export const LOADING_SHOW = "LOADING_SHOW";
export const LOADING_HIDE = "LOADING_HIDE";
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";
export const SNACKBAR_SHOW = "SNACKBAR_SHOW";
export const SNACKBAR_HIDE = "SNACKBAR_HIDE";
export const BRANDS_FETCH = "BRANDS_FETCH";
export const BRANDS_ADD = "BRANDS_ADD";
export const SMS_REQUEST = "SMS_REQUEST";
export const RESEND_SMS_REQUEST = "RESEND_SMS_REQUEST";
export const RESEND_SMS_STATUS_CHANGE = "RESEND_SMS_STATUS_CHANGE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ENCRYPTED_LOGIN_UNSUCCESSFUL = "ENCRYPTED_LOGIN_UNSUCCESSFUL";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const RECHARGE_VALUES_ADD = "RECHARGE_VALUES_ADD";
export const RECHARGE_VALUES_FETCH = "RECHARGE_VALUES_FETCH";
export const CARD_REGISTER = "CARD_REGISTER";
export const CARD_REGISTER_NEW_LAYOUT = "CARD_REGISTER_NEW_LAYOUT";
export const CLICK_BANNER = "CLICK_BANNER";
export const NEW_RECHARGE_CHANGE = "NEW_RECHARGE_CHANGE";
export const NEW_RECHARGE_REQUEST = "NEW_RECHARGE_REQUEST";
export const NEW_PIX_ENCRYPTED_RECHARGE_REQUEST = "NEW_PIX_ENCRYPTED_RECHARGE_REQUEST";
export const NEW_RECHARGE_RESET = "NEW_RECHARGE_RESET";
export const RESET_RECHARGE_VALUES = "RESET_RECHARGE_VALUES";
export const RECHARGES_FETCH = "RECHARGES_FETCH";
export const RECHARGES_ADD = "RECHARGES_ADD";
export const RECHARGES_SUCCESS = "RECHARGES_SUCCESS";
export const RECHARGES_ERROR = "RECHARGES_ERROR";
export const RECIPIENTS_FETCH = "RECIPIENTS_FETCH";
export const RECIPIENTS_ADD = "RECIPIENTS_ADD";
export const REQUEST_UPDATE_RECIPIENT = "REQUEST_UPDATE_RECIPIENT";
export const REQUEST_DELETE_RECIPIENT = "REQUEST_DELETE_RECIPIENT";
export const REQUEST_UPDATE_RECIPIENT_SUCCESS = "REQUEST_UPDATE_RECIPIENT_SUCCESS";
export const CPF_LOGIN_REQUEST = "CPF_LOGIN_REQUEST";
export const ENCRYPTED_LOGIN_REQUEST = "ENCRYPTED_LOGIN_REQUEST";
export const TV_PACKAGES_FETCH = "TV_PACKAGES_FETCH";
export const TV_PACKAGES_ADD = "TV_PACKAGES_ADD";
export const NEW_TV_RECHARGE_CHANGE = "NEW_TV_RECHARGE_CHANGE";
export const NEW_TV_RECHARGE_SUCCESS = "NEW_TV_RECHARGE_SUCCESS";
export const NEW_TV_RECHARGE_ERROR = "NEW_TV_RECHARGE_ERROR";
export const TV_PAYMENT_REQUEST = "TV_PAYMENT_REQUEST";
export const PAYMENT_METHODS_FETCH = "PAYMENT_METHODS_FETCH";
export const PAYMENT_METHODS_ADD = "PAYMENT_METHODS_ADD";
export const PAYMENT_METHODS_REMOVE_REQUEST = "PAYMENT_METHODS_REMOVE_REQUEST";
export const PAYMENT_METHODS_REMOVE_SUCCESS = "PAYMENT_METHODS_REMOVE_SUCCESS";
export const PAYMENT_METHODS_STATUS_RESET = "PAYMENT_METHODS_STATUS_RESET";
export const NEW_SCHEDULED_RECHARGE_REQUEST = "NEW_SCHEDULED_RECHARGE_REQUEST";
export const SCHEDULED_RECHARGES_SUCCESS = "SCHEDULED_RECHARGES_SUCCESS";
export const SCHEDULED_RECHARGES_ERROR = "SCHEDULED_RECHARGES_ERROR";
export const SCHEDULED_RECHARGES_FETCH = "SCHEDULED_RECHARGES_FETCH";
export const HISTORIC_SCHEDULED_RECHARGES_SAVE = "HISTORIC_SCHEDULED_RECHARGES_SAVE";
export const SCHEDULED_RECHARGES_SAVE = "SCHEDULED_RECHARGES_SAVE";
export const SCHEDULED_RECHARGES_FILTER = "SCHEDULED_RECHARGES_FILTER";
export const EDITABLE_SCHEDULED_RECHARGE_SAVE = "EDITABLE_SCHEDULED_RECHARGE_SAVE";
export const EDITABLE_SCHEDULED_RECHARGE_CHANGE = "EDITABLE_SCHEDULED_RECHARGE_CHANGE";
export const EDITABLE_SCHEDULED_RECHARGE_RESET = "EDITABLE_SCHEDULED_RECHARGE_RESET";
export const UPDATE_SCHEDULED_RECHARGE_REQUEST = "UPDATE_SCHEDULED_RECHARGE_REQUEST";
export const UPDATE_SCHEDULED_RECHARGE_SUCCESS = "UPDATE_SCHEDULED_RECHARGE_SUCCESS";
export const UPDATE_SCHEDULED_RECHARGE_FAILURE = "UPDATE_SCHEDULED_RECHARGE_FAILURE";
export const DELETE_SCHEDULED_RECHARGE_REQUEST = "DELETE_SCHEDULED_RECHARGE_REQUEST";
export const MANAGE_SCHEDULE_RECHARGE = "MANAGE_SCHEDULE_RECHARGE";
export const HOME_OPEN = "HOME_OPEN";
export const MINHA_CLARO_HOME_OPEN = "MINHA_CLARO_HOME_OPEN";
export const REGISTER_CREDIT_CARD_REQUEST = "REGISTER_CREDIT_CARD_REQUEST";
export const REGISTER_CREDIT_CARD_SUCCESS = "REGISTER_CREDIT_CARD_SUCCESS";
export const REGISTER_CREDIT_CARD_FAILURE = "REGISTER_CREDIT_CARD_FAILURE";
export const EXPRESS_RECHARGE_SAVE = "EXPRESS_RECHARGE_SAVE";
export const EXPRESS_RECHARGE_CHANGE = "EXPRESS_RECHARGE_CHANGE";
export const CONFIRM_EXPRESS_RECHARGE_REQUEST = "CONFIRM_EXPRESS_RECHARGE_REQUEST";
export const EXPRESS_RECHARGE_STATUS_CHANGE = "EXPRESS_RECHARGE_STATUS_CHANGE";
export const RESET_EXPRESS_RECHARGE_REQUEST = "RESET_EXPRESS_RECHARGE_REQUEST";
export const EDITABLE_SCHEDULED_RECHARGE_STATUS_CHANGE =
  "EDITABLE_SCHEDULED_RECHARGE_STATUS_CHANGE";
export const RESET_EDITABLE_SCHEDULED_RECHARGE_REQUEST =
  "RESET_EDITABLE_SCHEDULED_RECHARGE_REQUEST";
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const CHANGE_MEDIA = "CHANGE_MEDIA";
export const CREATE_PAYPAL_ACCOUNT = "CREATE_PAYPAL_ACCOUNT";
export const DELETE_PAYPAL_ACCOUNT = "DELETE_PAYPAL_ACCOUNT";
export const PROCESS_PAYPAL_AUTHORIZED = "PROCESS_PAYPAL_AUTHORIZED";
export const REQUEST_RECHARGE_BALANCE = "REQUEST_RECHARGE_BALANCE";
export const REQUEST_RECHARGE_BALANCE_SUCCESS = "REQUEST_RECHARGE_BALANCE_SUCCESS";
export const REQUEST_RECHARGE_BALANCE_FAIL = "REQUEST_RECHARGE_BALANCE_FAIL";
export const TARGET_BOX_SELECT = "TARGET_BOX_SELECT";
export const NEW_NO_CREDIT_RECHARGE_REQUEST = "NEW_NO_CREDIT_RECHARGE_REQUEST";
export const IDENTIFIER_ADD = "IDENTIFIER_ADD";
export const FINGERPRINT_SAVE = "FINGERPRINT_SAVE";
export const INITIALIZE_GA = "INITIALIZE_GA";
export const SELECT_RECHARGE_BOX = "SELECT_RECHARGE_BOX";
export const SELECTED_RECHARGE_VALUE = "SELECTED_RECHARGE_VALUE";
export const SELECTED_PAYMENT_FORM = "SELECTED_PAYMENT_FORM";
export const SELECTED_INSTALLMENT = "SELECTED_INSTALLMENT";
export const CHECK_RECHARGE_TYPE = "CHECK_RECHARGE_TYPE";
export const SELECT_RECHARGE_FREQUENCY = "SELECT_RECHARGE_FREQUENCY";
export const CREDIT_CARD_SELECTION = "CREDIT_CARD_SELECTION";
export const CREATE_PAYPAL_ACCOUNT_FAILURE = "CREATE_PAYPAL_ACCOUNT_FAILURE";
export const SCHEDULED_RECHARGE_BUTTON_CLICK = "SCHEDULED_RECHARGE_BUTTON_CLICK";
export const SCHEDULED_RECHARGE_DAY_SELECTION = "SCHEDULED_RECHARGE_DAY_SELECTION";
export const PROMOTION_FETCH = "PROMOTION_FETCH";
export const PROMOTION_ADD = "PROMOTION_ADD";
export const ENCRYPTED_LOGIN_REGISTER = "ENCRYPTED_LOGIN_REGISTER";
export const FEATURE_FETCH = "FEATURE_FETCH";
export const PUBLIC_FEATURE_FETCH = "PUBLIC_FEATURE_FETCH";
export const FEATURE_ADD = "FEATURE_ADD";
export const LANDING_PAGE_VALUE = "LANDING_PAGE_VALUE";
export const PAGE_LOAD_PRIVATE_LABEL = "PAGE_LOAD_PRIVATE_LABEL";
export const PAGE_SHOW_PRIVATE_LABEL = "PAGE_SHOW_PRIVATE_LABEL";
export const SAVE_CUSTOMER = "SAVE_CUSTOMER";
export const SAVE_GOVISA_STATUS = "SAVE_GOVISA_STATUS";
export const SAVE_GOVISA_CURRENT_STATE = "SAVE_GOVISA_CURRENT_STATE";
export const UPDATE_GOVISA_SUBSCRIPTION = "UPDATE_GOVISA_SUBSCRIPTION";
export const ERROR_ON_GOVISA = "ERROR_ON_GOVISA";
export const RESET_STATE = "RESET_STATE";
export const SHOW_OTP_MODAL = "SHOW_OTP_MODAL";
export const HIDE_OTP_MODAL = "HIDE_OTP_MODAL";
export const OTP_VALIDATE_SMS_TOKEN = "OTP_VALIDATE_SMS_TOKEN";
export const OTP_RESEND_SMS_TOKEN = "OTP_RESEND_SMS_TOKEN";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const UNVALIDATE_TOKEN = "UNVALIDATE_TOKEN";
export const RESET_STATE_OTP = "RESET_STATE_OTP";
export const RECHARGE_RESUME_ADD = "RECHARGE_RESUME_ADD";
export const RESET_RECHARGE_RESUME = "RESET_RECHARGE_RESUME";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
