import paymentMethodsEnum from "../enum/paymentMethods";
import { orderCardsByConfirmed } from "../lib/utils";

const getLoginCardToken = ({ paymentMethodState }) => paymentMethodState.loginCardToken;

const getCardsPerType = (paymentMethods, type) => {
  let cards = [];

  if (paymentMethods.find(pm => pm.type === type)) {
    cards = paymentMethods.find(pm => pm.type === type).elements;
  }

  return cards;
};

const getCreditCards = ({ paymentMethodState }) => {
  let creditCards = [];

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.CREDITO)) {
    creditCards = paymentMethodState.paymentMethods.find(
      pm => pm.type === paymentMethodsEnum.CREDITO
    ).elements;
  }

  return creditCards;
};

const getDebitCards = ({ paymentMethodState }) => {
  let creditCards = [];

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.DEBITO_CEF)) {
    creditCards = paymentMethodState.paymentMethods.find(
      pm => pm.type === paymentMethodsEnum.DEBITO_CEF
    ).elements;
  }

  return creditCards;
};

const getCardsByType = ({ paymentMethodState, rechargeState }) => {
  const paymentType = rechargeState.newRecharge.paymentMethod.type;

  let cards = [];

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentType)) {
    cards = paymentMethodState.paymentMethods.find(pm => pm.type === paymentType).elements;
  }
  return orderCardsByConfirmed(cards);
};

const getNumberOfCards = ({ paymentMethodState }) => getCreditCards({ paymentMethodState }).length;

const getNumberOfDebitCards = ({ paymentMethodState }) =>
  getDebitCards({ paymentMethodState }).length;

const hasAnyCreditCards = ({ paymentMethodState }) => getNumberOfCards({ paymentMethodState }) >= 1;

const hasAnyDebitCards = ({ paymentMethodState }) =>
  getNumberOfDebitCards({ paymentMethodState }) >= 1;

const getMaxLimitCards = ({ paymentMethodState }) => {
  let maxLimitCards = 0;

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.CREDITO)) {
    maxLimitCards = paymentMethodState.paymentMethods.find(
      pm => pm.type === paymentMethodsEnum.CREDITO
    ).maxLimit;
  }

  return maxLimitCards;
};

const getMaxLimitDebitCards = ({ paymentMethodState }) => {
  let maxLimitCards = 0;

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.DEBITO_CEF)) {
    maxLimitCards = paymentMethodState.paymentMethods.find(
      pm => pm.type === paymentMethodsEnum.DEBITO_CEF
    ).maxLimit;
  }

  return maxLimitCards;
};

const isAbleToAddCard = ({ paymentMethodState }) =>
  getNumberOfCards({ paymentMethodState }) < getMaxLimitCards({ paymentMethodState });

const isAbleToAddDebitCard = ({ paymentMethodState }) =>
  getNumberOfDebitCards({ paymentMethodState }) < getMaxLimitDebitCards({ paymentMethodState });

const getPaypalAccounts = ({ paymentMethodState }) => {
  let paypalAccounts = [];

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.PAYPAL)) {
    paypalAccounts = paymentMethodState.paymentMethods.find(
      pm => pm.type === paymentMethodsEnum.PAYPAL
    ).elements;
  }

  return paypalAccounts;
};

const getNumberOfPaypalAccounts = ({ paymentMethodState }) =>
  getPaypalAccounts({ paymentMethodState }).length;

const getMaxLimitPaypalAccounts = ({ paymentMethodState }) => {
  let maxLimitPaypalAccounts = 0;

  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.PAYPAL)) {
    maxLimitPaypalAccounts = paymentMethodState.paymentMethods.find(
      pm => pm.type === paymentMethodsEnum.PAYPAL
    ).maxLimit;
  }

  return maxLimitPaypalAccounts;
};

const getPaymentMethodsStatus = ({ paymentMethodState }) => paymentMethodState.status;

const getGPayData = ({ paymentMethodState }) => {
  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.GOOGLE_PAY)) {
    return paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.GOOGLE_PAY)
      .data;
  }
  return null;
};

const getMercadoPago = ({ paymentMethodState }) => {
  if (paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.MERCADO_PAGO)) {
    return paymentMethodState.paymentMethods.find(pm => pm.type === paymentMethodsEnum.MERCADO_PAGO)
      .label;
  }
  return null;
};

const hasGPayData = ({ paymentMethodState }) =>
  getGPayData({ paymentMethodState }) !== undefined && getGPayData({ paymentMethodState }) !== null;

const hasMercadoPago = ({ paymentMethodState }) =>
  getMercadoPago({ paymentMethodState }) !== null &&
  getMercadoPago({ paymentMethodState }) !== undefined;

const getPaymentMethods = ({ paymentMethodState }) => paymentMethodState.paymentMethods;

export {
  getCreditCards,
  getDebitCards,
  getNumberOfCards,
  getNumberOfDebitCards,
  hasAnyCreditCards,
  hasAnyDebitCards,
  getMaxLimitCards,
  getMaxLimitDebitCards,
  isAbleToAddCard,
  isAbleToAddDebitCard,
  getPaymentMethodsStatus,
  getPaypalAccounts,
  getNumberOfPaypalAccounts,
  getMaxLimitPaypalAccounts,
  getGPayData,
  hasGPayData,
  getMercadoPago,
  hasMercadoPago,
  getLoginCardToken,
  getPaymentMethods,
  getCardsByType,
  getCardsPerType,
};
