import { FINGERPRINT_SAVE, RESET_STATE } from "../constants/actionTypes";

const INITIAL_STATE = {
  fingerprint: "",
};

const applySaveFingerprint = (state, action) => ({
  ...state,
  fingerprint: action.payload,
});

const fraudAnalysisReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FINGERPRINT_SAVE:
      return applySaveFingerprint(state, action);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default fraudAnalysisReducer;
