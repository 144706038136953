import { RESET_STATE, RECHARGE_RESUME_ADD, RESET_RECHARGE_RESUME } from "../constants/actionTypes";

const INITIAL_STATE = {
  rechargeValue: 0,
  rechargeBonus: "",
  nextScheduleDate: "",
};

const applyAddRechargeResume = (state, action) => {
  const { rechargeValue, rechargeBonus, nextScheduleDate } = action.payload;
  return { ...state, rechargeValue, rechargeBonus, nextScheduleDate };
};

const applyResetRechargeResume = state => ({
  ...state,
  rechargeValue: INITIAL_STATE.rechargeValue,
  rechargeBonus: INITIAL_STATE.rechargeBonus,
  nextScheduleDate: INITIAL_STATE.nextScheduleDate,
});

const rechargeResumeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECHARGE_RESUME_ADD:
      return applyAddRechargeResume(state, action);
    case RESET_RECHARGE_RESUME:
      return applyResetRechargeResume(state);
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default rechargeResumeReducer;
