import {
  RECIPIENTS_FETCH,
  RECIPIENTS_ADD,
  REQUEST_UPDATE_RECIPIENT,
  REQUEST_UPDATE_RECIPIENT_SUCCESS,
  REQUEST_DELETE_RECIPIENT,
} from "../constants/actionTypes";

const doFetchRecipients = () => ({
  type: RECIPIENTS_FETCH,
});

const doSaveRecipients = recipients => ({
  type: RECIPIENTS_ADD,
  payload: recipients,
});

const doRequestUpdateRecipients = (oldMsisdn, newMsisdn, newName) => ({
  type: REQUEST_UPDATE_RECIPIENT,
  msisdn: oldMsisdn,
  payload: { msisdn: newMsisdn, name: newName },
});

const doSuccessUpdateRecipients = (msisdn, payload) => ({
  type: REQUEST_UPDATE_RECIPIENT_SUCCESS,
  msisdn,
  payload,
});

const doRequestDeleteRecipient = msisdn => ({
  type: REQUEST_DELETE_RECIPIENT,
  msisdn,
});

export {
  doFetchRecipients,
  doSaveRecipients,
  doRequestUpdateRecipients,
  doSuccessUpdateRecipients,
  doRequestDeleteRecipient,
};
